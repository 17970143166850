import React from 'react';
import { Box, Button, Typography, Card, CardContent, Grid, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SchoolIcon from '@mui/icons-material/School';
import SubjectIcon from '@mui/icons-material/Subject';
import BusinessIcon from '@mui/icons-material/Business';
import { useLanguage } from '../../contexts/languageContext';

const buttonStyle = {
    mt: 4,
    mb: 4,
    minWidth: '230px',
    minHeight: '50px',
    borderRadius: '10px',
    background: 'linear-gradient(45deg, #1561AC 30%, #186CC0 90%)',
    color: 'white',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)',
    '&:hover': {
        background: 'linear-gradient(45deg, #186CC0 30%, #1561AC 90%)',
    }
};

const cardStyle = {
    backgroundColor: '#F9FAFB',
    borderRadius: 2,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
};

const SummaryStep = ({ selectedRole, selectedSubjects, institutionName, onStartFreeTrial, nextBtnLoading }) => {
    const { languageData } = useLanguage()
    return (
        <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ px: 2 }}>
            <Grid item xs={12}>
                <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>{languageData?.onboarding?.summary_step?.title || "Summary"}</Typography>
                <Typography variant="subtitle1" align="center" sx={{ mb: 4 }}>
                    {languageData?.onboarding?.summary_step?.subtitle || "Please review the details below before getting started."}
                </Typography>
            </Grid>

            {selectedRole && (
                <Grid item xs={12} md={4}>
                    <Card sx={cardStyle}>
                        <SchoolIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{languageData?.onboarding?.summary_step?.role || "Role"}</Typography>
                        <Typography variant="body1">{selectedRole}</Typography>
                    </Card>
                </Grid>
            )}

            {/* {selectedSubjects.length > 0 && (
                <Grid item xs={12} md={4}>
                    <Card sx={cardStyle}>
                        <SubjectIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{languageData?.onboarding?.summary_step?.subject || "Subjects"}</Typography>
                        <Typography variant="body1">{selectedSubjects.join(', ')}</Typography>
                    </Card>
                </Grid>
            )} */}

            {institutionName && (
                <Grid item xs={12} md={4}>
                    <Card sx={cardStyle}>
                        <BusinessIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{languageData?.onboarding?.summary_step?.institution || "Institution Name"}</Typography>
                        <Typography variant="body1">{institutionName}</Typography>
                    </Card>
                </Grid>
            )}

            <Grid item xs={12}>
                <Box textAlign="center">
                    <Button
                        variant="contained"
                        onClick={onStartFreeTrial}
                        sx={buttonStyle}
                    // start={!nextBtnLoading && <AutoAwesomeIcon />}
                    >
                        {nextBtnLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : languageData?.onboarding?.summary_step?.start_button || 'Click Here To Start'}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

SummaryStep.propTypes = {
    selectedRole: PropTypes.string.isRequired,
    selectedSubjects: PropTypes.arrayOf(PropTypes.string).isRequired,
    institutionType: PropTypes.string,
    institutionName: PropTypes.string.isRequired,
    onStartFreeTrial: PropTypes.func.isRequired,
    nextBtnLoading: PropTypes.bool.isRequired,
};

export default SummaryStep;
