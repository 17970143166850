import React, { useEffect, useState } from 'react'
import { Button, TextField, Divider, Link, Box, Typography, Container, } from '@mui/material'
import ContinueWithGoogleButton from '../components/GoogleAuth'
import ContinueWithMicrosoftButton from '../components/MicrosoftAuth'
import { HttpStatusCode } from 'axios'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { Stack } from '@mui/system'
import { useUser } from '../hooks/useUser'
import { Helmet } from 'react-helmet-async';
import api from '../requests/api'
import { handleRedirectUrlAfterSignIn, handleSelectedtierIfExist } from '../utils'
import { validateEmail, validatePassword } from '../utils/inputValidations'
import useHistory from '../hooks/useHistory'
import PrivacyAndTermsOfService from '../components/PrivacyAndTerms0fService'
import { homepage } from '../components/menu/MenuLinks'
import { handleRedirectAfterAuth } from '../utils/auth'
import useSnackBar from '../hooks/useSnackBar'
import { SnackbarTypes } from '../utils/snackbarTypes'
import Header from '../components/Header'
import { useLanguage } from '../contexts/languageContext'
import { getTempToken } from '../requests/token'
import HebrewPrivacyAndTermsOfService from '../components/HebrewPrivacyAndTermsOfService'
import { IdmMoeILButton } from '../components/IdmMoeILButton'

export default function Login() {
  const navigate = useNavigate()
  const userContext = useUser()
  const historyContext = useHistory()
  const { openSnackBar } = useSnackBar()
  const { languageData, fetchLanguageWithToken, isRTL } = useLanguage();

  useEffect(() => {
    userContext.isLoggedIn().then(res => {
      if (res) {
        handleSelectedtierIfExist()
        handleRedirectUrlAfterSignIn(navigate, homepage)
      } else {
        getTempToken(null).then(token => {
          if (token)
            fetchLanguageWithToken(token)
        })
      }
    }).catch(e => console.error(e))
  }, [])

  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [isFormValid, setisFormValid] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  })

  // ################# Form Functions #################
  const handleTextFieldChange = (event) => {
    const { name, value } = event.target
    let newIsFormValid = { ...isFormValid }

    switch (name) {
      case 'email':
        newIsFormValid[name] = validateEmail(value)
        break
      case 'password':
        newIsFormValid[name] = value.length >= 6
        break
      default:
        break
    }

    setisFormValid(newIsFormValid)
  }

  const checkValidForm = (obj) => {
    const { email, password } = obj
    return email && password
  }

  // ################# Email functions #################  
  const handleEmailChange = (event) => {
    const input = event.target.value
    setEmailError(!validateEmail(input))
  }

  // ################# Password functions #################  
  const handlePasswordChange = (event) => {
    const input = event.target.value
    setPasswordError(!validatePassword(input))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const loginFailed = (type = SnackbarTypes.ERROR.field) => {
      setIsLoading(false)
      openSnackBar(type)
    }
    if (!checkValidForm(isFormValid)) {
      loginFailed(SnackbarTypes.INVALID_CREDENTIALS.field)
      return;
    }

    setIsLoading(true)
    const data = new FormData(event.currentTarget)
    const user = {
      email: data.get('email'),
      password: data.get('password'),
    }

    await api.post(`/auth/login`, { user }).then(async (response) => {
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false)
        userContext.login(response.data)
        historyContext.resetHistory()
        // handleSelectedtierIfExist()
        handleRedirectAfterAuth(navigate, response?.data)
      }
      else {
        if (response.status === HttpStatusCode.Unauthorized)
          loginFailed(SnackbarTypes.VERIFY_EMAIL_BEFORE_LOGIN.field)
        else if (response.status === HttpStatusCode.TooManyRequests)
          loginFailed(SnackbarTypes.RATE_LIMIT_EXCEEDED.field)
        else
          loginFailed()
      }
    }).catch(err => {
      if (err.response.status === HttpStatusCode.Unauthorized) {
        const encoded_email = btoa(data.get('email'));
        navigate(`/validation/stop?e=${encoded_email}`);
      }
      else if (err.response.status === HttpStatusCode.TooManyRequests)
        loginFailed(SnackbarTypes.RATE_LIMIT_EXCEEDED.field)
      else {
        loginFailed()
      }
    })
    // headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
  }

  return (
    <>
      <Helmet> <title>Exam Generator | Login</title> <meta name="description" content={"Login to the AI-powered exam generator, to create high-quality, personalized exams in seconds. Effortlessly generate various question types, customize difficulty and length, and save time while boosting learning outcomes. New users? Sign up!"} /> </Helmet>
      <Header />
      <Container component='main' maxWidth='s'>
        <Box sx={{ display: 'flex', marginTop: 2, flexDirection: 'column', alignItems: 'center', direction: isRTL ? 'rtl' : 'ltr' }} >
          <Typography component='h1' variant='h5'> {languageData?.login?.title ?? 'Login'} </Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} >
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              type='email'
              label={languageData?.login?.email_label ?? 'Email Address'}
              name='email'
              onChange={handleEmailChange}
              error={emailError}
              onInput={handleTextFieldChange}
              helperText={emailError ? (languageData?.login?.email_helper_Text ?? 'Please enter a valid email') : ''}
              autoComplete='email'
              size='small'
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label={languageData?.login?.password_label ?? 'Password'}
              onChange={handlePasswordChange}
              type='password'
              onInput={handleTextFieldChange}
              error={passwordError}
              helperText={
                passwordError ? languageData?.login?.password_helper_Text ?? 'Password must be at least 6 characters long' : ''
              }
              id='password'
              autoComplete='current-password'
              size='small'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 2, mb: 1 }}
            >
              {isLoading ? (
                <CircularProgress color='inherit' size={24} />
              ) : (
                languageData?.login?.login_btn ?? 'Login'
              )}
            </Button>
            <Link href='/forgot-password' variant='body2' rel="noopener noreferrer" sx={{ display: 'block', width: 'fit-content' }}>{languageData?.login?.forgot_password ?? 'Forgot Password?'}</Link>
            <Stack spacing={2}>
              <Divider style={{ margin: '10px 0px' }} />
              <ContinueWithGoogleButton />
              <ContinueWithMicrosoftButton />
              {/* <IdmMoeILButton /> */}
              {isRTL ? <HebrewPrivacyAndTermsOfService buttonText={"התחברות"} operationText={"לחיצה על"} /> : <PrivacyAndTermsOfService buttonText={"Logging In"} />}
              <Box sx={{ mt: 2 }} />
              <Link href='signup' variant='body2' sx={{ width: 'fit-content' }}> {languageData?.login?.no_account ?? "Don't have an account? Sign Up"} </Link>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  )
}
