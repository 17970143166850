import ExamGenerator from "../components/ExamGenerator";
import FileUploadHandler from "../components/FileUploadHandlerForExam";
import {
    Calculate as CalculateIcon,
    Science as ScienceIcon,
    History as HistoryIcon,
    Language as EnglishIcon,
    Public as GeographyIcon,
    Computer as ComputerScienceIcon,
    AllInclusive as PhysicsIcon,
    Biotech as ChemistryIcon,
    LocalFlorist as BiologyIcon,
    Palette as ArtIcon,
    MusicNote as MusicIcon,
    DirectionsRun as PhysicalEducationIcon,
    AccountBalance as EconomicsIcon,
    Psychology as PsychologyIcon,
    People as SociologyIcon,
} from '@mui/icons-material';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import BalanceIcon from '@mui/icons-material/Balance';
import GavelIcon from '@mui/icons-material/Gavel';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GroupsIcon from '@mui/icons-material/Groups';
import { cardChips } from "./chips";

export const inputTypes = {
    text: 'text',
    topic: 'topic',
    url: 'url',
    uploads: 'uploads',
    youtube: 'youtube',
    based: 'based',
    uploadYourExam: 'uploadYourExam',
    unseen: 'unseen',
    math: 'math'
}

export const tabsConfig = [
    { label: 'Text', hebrewLabel: 'טקסט', field: inputTypes.text, multiline: true, rows: 10, limit: 10000, placeholder: 'The attack on Pearl Harbor on December 7, 1941, was a pivotal moment that thrust the United States into World War II. Tensions between Japan and the U.S. had been escalating, leading to Japans plan to strike the U.S. Pacific Fleet. In a surprise attack, Japanese naval forces launched an assault on Pearl Harbor, causing significant damage to the American fleet. The attack resulted in the sinking of battleships like the USS Arizona and led to the loss of thousands of lives. The U.S. responded by declaring war on Japan and joining the Allied forces. The attack marked a turning point in the war and transformed the United States into a global superpower. Its long-term consequences reshaped international relations and led to the internment of Japanese Americans. The attack on Pearl Harbor remains a significant event in history, serving as a reminder of the need for preparedness and the impact of decisive actions.' },
    { label: 'Topic', hebrewLabel: 'נושא', field: inputTypes.topic, limit: 50, rows: 1, placeholder: 'Topic for example: `Coffee`' },
    { label: 'Uploads', hebrewLabel: 'העלאת קבצים', field: inputTypes.uploads, fileInput: true, limit: 10000 },
    { label: 'URL', hebrewLabel: 'קישור', field: inputTypes.url, urlInput: true, limit: 200, rows: 1, placeholder: 'https://www.google.com' },
    { label: 'YouTube', hebrewLabel: 'יוטיוב', field: inputTypes.youtube, urlInput: true, limit: 200, rows: 1, placeholder: 'https://www.youtube.com/watch?v=Tn6-PIqc4UM&t=49s&ab_channel=Fireship' },
];

export const feedbacksLinks = {
    teacher: 'https://docs.google.com/forms/d/e/1FAIpQLSebW8nVN-nhiKuDU0nS2OsNWGd_HjPQiidF04iAfAKFhUoalg/viewform?usp=pp_url&entry.906224203=', // https://docs.google.com/forms/d/1L8g_iAOorbFmSq1FMan7xFza03DUo30VJbAhk-ylCoo/edit
    default: 'https://forms.gle/DMWGPM5XhENQ9H6b7'
}
export const featuresConfig = [
    {
        label: 'Free Text',
        hebrewLabel: 'טקסט חופשי',
        field: inputTypes.text,
        placeholder: 'The history of mankind.',
        shortExplain: 'Copy and Paste a detailed text related to your desired exam.',
        hebrewShortExplain: 'העתיקו והדביקו או רשמו טקסט מפורט הקשור למבחן אותו ברצונכם ליצור',
        component: (user, inputText, handleTextInputChange) => (
            <ExamGenerator
                limit={10000}
                inputText={inputText}
                userRoles={user?.roles ?? ['FREE']}
                rows={5}
                placeholder={'The history of mankind..'}
                onInput={(value) => handleTextInputChange(value, 'text')}
                field={inputTypes.text}
            />
        ),
    },
    {
        label: 'Topic',
        hebrewLabel: 'בחירת נושא',
        field: inputTypes.topic,
        placeholder: 'Topic for example: `Science`',
        shortExplain: 'Specify a concise topic, could be any topic: Science / Psychology / History',
        hebrewShortExplain: 'יצירת מבחנים לפי נושאים, לדוגמה: ספרות, כימיה, פסיכולוגיה.',
        component: (user, inputText, handleTextInputChange) => (
            <ExamGenerator
                limit={50}
                inputText={inputText}
                userRoles={user?.roles ?? ['FREE']}
                rows={1}
                placeholder="Topic for example: `Coffee`"
                onInput={(value) => handleTextInputChange(value, 'topic')}
                field={inputTypes.topic}
            />
        ),
    },
    {
        label: 'Upload Files',
        hebrewLabel: 'העלאת קבצים',
        field: inputTypes.uploads,
        shortExplain: 'Upload relevant files in PDF or PPTX format.',
        hebrewShortExplain: 'העלו קבצי PDF או PPTX',
        chip: cardChips.hot.component,
        component: (user, inputText, handleTextInputChange) => (
            <FileUploadHandler
                userRoles={user?.roles ?? ['FREE']}
                onInput={(value) => handleTextInputChange(value, 'uploads')}
            />
        ),
    },
    {
        label: 'Upload Your Exam',
        hebrewLabel: 'העלאת מבחן קיים',
        field: inputTypes.uploadYourExam,
        shortExplain: 'Upload your own exam file to get answers and share with students.',
        hebrewShortExplain: 'העלה מבחן לשיתוף עם תלמידים ולקבלת מחוון תשובות ו-AI שיבדוק אוטומטית',
        chip: cardChips.beta.component,
        component: (user, inputText, handleTextInputChange) => (
            <FileUploadHandler
                userRoles={user?.roles ?? ['FREE']}
                onInput={(value) => handleTextInputChange(value, 'uploadYourExam')}
                maxFiles={1}
            />
        ),
    },
    {
        label: 'URL',
        hebrewLabel: 'קישור',
        field: inputTypes.url,
        placeholder: 'https://www.google.com',
        shortExplain: 'Provide a link to a web page, article or a report for exam creation.',
        hebrewShortExplain: 'הדביקו קישור לדף אינטרנט, מאמר או כתבה ליצירת מבחן',
        component: (user, inputText, handleTextInputChange) => (
            <ExamGenerator
                limit={200}
                inputText={inputText}
                userRoles={user?.roles ?? ['FREE']}
                rows={1}
                placeholder="https://www.google.com"
                onInput={(value) => handleTextInputChange(value, 'url')}
                field={inputTypes.url}
            />
        ),
    },
    {
        label: 'Unseen',
        hebrewLabel: 'קטע עלום (אנסין)',
        field: inputTypes.unseen,
        placeholder: 'Topic for example: `Science`',
        shortExplain: 'Generate an unseen with questions.',
        hebrewShortExplain: 'יצירת מבחן אנסין לפי נושא, לדוגמה: ספרות, כימיה, פסיכולוגיה.',
        chip: cardChips.new.component,
        component: (user, inputText, handleTextInputChange) => (
            <ExamGenerator
                limit={200}
                inputText={inputText}
                userRoles={user?.roles ?? ['FREE']}
                rows={1}
                placeholder="Topic for example: `Coffee`"
                onInput={(value) => handleTextInputChange(value, 'unseen')}
                field={inputTypes.unseen}
            />
        ),
    },
    // {
    //     label: 'YouTube Video',
    //     hebrewLabel: 'סרטון יוטיוב',
    //     field: inputTypes.youtube,
    //     urlInput: true,
    //     limit: 200,
    //     rows: 1,
    //     placeholder: 'https://www.youtube.com/watch?v=Tn6-PIqc4UM&t=49s&ab_channel=Fireship',
    //     shortExplain: 'Paste a YouTube video URL. Works only if video has subtitles.',
    //     hebrewShortExplain: 'הדביקו קישור לסרטון YouTube לחילוץ טקסט במידה ויש כתוביות מובנות',
    //     component: (user, inputText, handleTextInputChange) => (
    //         <ExamGenerator
    //             limit={200}
    //             inputText={inputText}
    //             userRoles={user?.roles ?? ['FREE']}
    //             rows={1}
    //             placeholder="https://www.youtube.com/watch?v=Tn6-PIqc4UM&t=49s&ab_channel=Fireship"
    //             onInput={(value) => handleTextInputChange(value, 'youtube')}
    //             field={inputTypes.youtube}
    //         />
    //     ),
    // },
    // {
    //     label: 'Existing Exam',
    //     hebrewLabel: 'מבוסס מבחן אחר',
    //     field: 'based',
    //     fileInput: true,
    //     limit: 10000,
    //     shortExplain: 'Generate a new exam based on an existing one, upload the existing exam file.',
    //     hebrewShortExplain: 'צרו מבחן חדש בהתבסס על קיים, העלו את קובץ המבחן הקיים',
    //     component: (user, inputText, handleTextInputChange) => (
    //         <UploadFile
    //             userRoles={user?.roles ?? ['FREE']}
    //             onInput={(value) => handleTextInputChange(value, 'based')}
    //             helperText={
    //                 {
    //                     en: 'You have the flexibility to choose parameters, and the new exam will be dynamically generated based on your selections. The new exam will also incorporate the subject and content of the old exam.',
    //                     he: 'יש לך אפשרות לבחור פרמטרים, והמבחן החדש ייווצר דינמית לפי הבחירות שלך. המבחן החדש יכיל גם את נושא ותוכן המבחן הקודם'
    //                 }
    //             }
    //         />
    //     ),
    // },
    {
        label: 'Math',
        hebrewLabel: 'מתמטיקה',
        field: inputTypes.math,
        placeholder: 'Enter math related content here...',
        shortExplain: 'Create Graphs, Equations and more math related questions',
        hebrewShortExplain: 'הזן תוכן הקשור למתמטיקה',
        chip: cardChips.new.component,
        component: (user, inputText, handleTextInputChange) => (
            <ExamGenerator
                limit={10000}
                inputText={inputText}
                userRoles={user?.roles ?? ['FREE']}
                rows={2}
                placeholder="Enter math related content here..."
                onInput={(value) => handleTextInputChange(value, 'math')}
                field={inputTypes.math}
            />
        ),
    },
    // {
    //     label: 'Speech to Exam',
    //     hebrewLabel: 'המרת דיבור למבחן',
    //     field: 'speechToExam',
    //     shortExplain: 'Speech to exam feature will be available soon.',
    //     hebrewShortExplain: 'היכולת להמיר דיבור למבחן תהיה זמינה בקרוב',
    //     component: () => (
    //         <div>Coming soon</div>
    //     ),
    // },
    // {
    //     label: 'Upload Pictures',
    //     hebrewLabel: 'העלאת תמונות',
    //     field: 'uploadPictures',
    //     shortExplain: 'Upload Pictures feature will be available soon.',
    //     hebrewShortExplain: 'היכולת להעלות תמונות תהיה זמינה בקרוב',
    //     component: () => (
    //         <div>Coming soon</div>
    //     ),
    // },

];

export const websiteLinks = {
    STORE: "/pricing",
    FREE: "https://www.exam-generator.com/signup",
    MEMBERSHIP: (email) => `https://exam-generator.lemonsqueezy.com/checkout/buy/f4433c5b-9cab-4236-8fc6-c588ab71f7ce?checkout[email]=${email}&checkout[custom][user_email]=${email}`,
    PRO: (email) => `https://exam-generator.lemonsqueezy.com/checkout/buy/7e806f95-ec75-4477-977c-c527ea3ae71b?checkout[email]=${email}&checkout[custom][user_email]=${email}`,
    BASIC: (email) => `https://exam-generator.lemonsqueezy.com/checkout/buy/67a6317d-7eb2-4a57-a840-89bc23aa7e66?checkout[email]=${email}&checkout[custom][user_email]=${email}`,
    PREMIUM: (email) => `https://exam-generator.lemonsqueezy.com/checkout/buy/a10980e6-607c-4121-94d7-3a5935733139?checkout[email]=${email}&checkout[custom][user_email]=${email}`,
    SCHOOL: (email) => "https://forms.gle/gBncH9jdmWuhhmwt9",
    ENTERPRISE: "https://exam-generator.lemonsqueezy.com/contact",

}

export const tiers = [
    {
        title: 'FREE', price: '0', period: 'one time', link: websiteLinks.FREE, features: [
            { text: '5 Exams 📄', included: true },
            { text: '10,000 words per exam', included: true },
            { text: 'Google Forms Integration', included: true },
            { text: 'Export To PDF', included: true },
            { text: 'GPT-3.5', included: true },
            { text: 'Supports English', included: true },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
        ],
        buttonText: 'Sign Up For Free', buttonVariant: 'outlined',
    },
    {
        title: 'BASIC',
        price: '6.00',
        period: 'monthly',
        link: websiteLinks.BASIC,
        features: [
            { text: '10 Exams Per Month 📄', included: true },
            { text: '10,000 words per exam', included: true },
            { text: 'Google Forms', included: true },
            { text: 'Upload Files', included: true },
            { text: 'Export To PDF', included: true },
            { text: 'GPT-3.5', included: true },
            { text: 'Supports English', included: true },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
        ],
        buttonText: 'Join Us',
        buttonVariant: 'outlined',
    },
    {
        title: 'PRO',
        price: '15.00',
        period: 'monthly',
        link: websiteLinks.PRO,
        features: [
            { text: '30 Exams Per Month 📄', included: true },
            { text: '50,000 words per exam', included: true },
            { text: 'Google Forms', included: true },
            { text: 'Export To PDF', included: true },
            { text: 'GPT-4.0 Model', included: true },
            { text: 'Create exams from URL', included: true },
            { text: 'YouTube Videos', included: true },
            { text: 'Supports 10 languages', included: true },
            { text: 'Customize your exams', included: true },
            { text: ' ' },
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
    },
    {
        title: 'PREMIUM',
        price: '20.00',
        period: 'monthly',
        link: websiteLinks.PREMIUM,
        features: [
            { text: 'Unlimited Exams 📄', included: true },
            { text: '100,000 words per exam', included: true },
            { text: 'Google Forms', included: true },
            { text: 'Export To PDF', included: true },
            { text: 'Best AI model', included: true },
            { text: 'Create exams from URL', included: true },
            { text: 'YouTube Videos', included: true },
            { text: 'Supports 10 languages', included: true },
            { text: 'Customize your exams', included: true },
            { text: 'Get quicker response', included: true },
        ],
        buttonText: 'Become A Member',
        buttonVariant: 'contained',
    },
    {
        title: 'SCHOOL', price: 'Contact Us', period: 'dynamic', link: websiteLinks.STORE, //TODO: change to websiteLinks.ENTERPRISE
        features: [
            { text: 'Contact us for personalized offer' },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
            { text: ' ' },
        ],
        buttonText: 'Contact Us', buttonVariant: 'outlined',
    },
];

export const getSubjectsConfig = (languageData) => {

    return [
        { name: languageData?.onboarding?.subject_step?.math || 'Math', value: 'Mathematics', icon: <CalculateIcon /> },
        { name: languageData?.onboarding?.subject_step?.science || 'Science', value: 'Science', icon: <ScienceIcon /> },
        { name: languageData?.onboarding?.subject_step?.history || 'History', value: 'History', icon: <HistoryIcon /> },
        { name: languageData?.onboarding?.subject_step?.english || 'English', value: 'English', icon: <EnglishIcon /> },
        { name: languageData?.onboarding?.subject_step?.geography || 'Geography', value: 'Geography', icon: <GeographyIcon /> },
        { name: languageData?.onboarding?.subject_step?.computer_science || 'Computer Science', value: 'Computer Science', icon: <ComputerScienceIcon /> },
        { name: languageData?.onboarding?.subject_step?.physics || 'Physics', value: 'Physics', icon: <PhysicsIcon /> },
        { name: languageData?.onboarding?.subject_step?.chemistry || 'Chemistry', value: 'Chemistry', icon: <ChemistryIcon /> },
        { name: languageData?.onboarding?.subject_step?.biology || 'Biology', value: 'Biology', icon: <BiologyIcon /> },
        { name: languageData?.onboarding?.subject_step?.art || 'Art', value: 'Art', icon: <ArtIcon /> },
        { name: languageData?.onboarding?.subject_step?.music || 'Music', value: 'Music', icon: <MusicIcon /> },
        { name: languageData?.onboarding?.subject_step?.physical_education || 'Physical Education', value: 'Physical Education', icon: <PhysicalEducationIcon /> },
        { name: languageData?.onboarding?.subject_step?.economics || 'Economics', value: 'Economics', icon: <EconomicsIcon /> },
        { name: languageData?.onboarding?.subject_step?.psychology || 'Psychology', value: 'Psychology', icon: <PsychologyIcon /> },
        { name: languageData?.onboarding?.subject_step?.sociology || 'Sociology', value: 'Sociology', icon: <SociologyIcon /> },
        { name: languageData?.onboarding?.subject_step?.civics || 'Civics', value: 'Civics', icon: <GavelIcon /> },
        { name: languageData?.onboarding?.subject_step?.medical || 'Medical', value: 'Medical', icon: <MedicalServicesIcon /> },
        { name: languageData?.onboarding?.subject_step?.law || 'Law', value: 'Law', icon: <BalanceIcon /> },
    ];
};

export const getEnterpriseSubjectsConfig = (languageData) => {

    return [
        {
            name: languageData?.onboarding?.enterprise_subject_step?.employee_training || 'Employee Training',
            value: 'Employee Training',
            icon: <SchoolIcon />
        },
        {
            name: languageData?.onboarding?.enterprise_subject_step?.recruitment || 'Recruitment',
            value: 'Recruitment',
            icon: <WorkIcon />
        },
        {
            name: languageData?.onboarding?.enterprise_subject_step?.qualifications || 'Qualifications',
            value: 'Qualifications',
            icon: <BadgeIcon />
        },
        {
            name: languageData?.onboarding?.enterprise_subject_step?.tutorials || 'Tutorials',
            value: 'Tutorials',
            icon: <ImportContactsIcon />
        },
        {
            name: languageData?.onboarding?.enterprise_subject_step?.professional_exams || 'Professional Exams',
            value: 'Professional Exams',
            icon: <AssignmentIcon />
        },
        {
            name: languageData?.onboarding?.enterprise_subject_step?.management_training || 'Management Training',
            value: 'Management Training',
            icon: <BusinessCenterIcon />
        },
        {
            name: languageData?.onboarding?.enterprise_subject_step?.sales_training || 'Sales Training',
            value: 'Sales Training',
            icon: <TrendingUpIcon />
        },
        {
            name: languageData?.onboarding?.enterprise_subject_step?.customer_service_training || 'Customer Service Training',
            value: 'Customer Service Training',
            icon: <SupportAgentIcon />
        },
        {
            name: languageData?.onboarding?.enterprise_subject_step?.team_building || 'Team Building',
            value: 'Team Building',
            icon: <GroupsIcon />
        },
    ];
};


export const ctas = {
    CREATE_EXAM: {
        content: 'Create another exam.',
        link: '/exam',
        buttonLabel: 'Create',
        newTab: false,
        isRTL: false
    },
    TRY_GPT4: {
        content: 'Create better results',
        link: websiteLinks.STORE,
        buttonLabel: 'Upgrade',
        newTab: false,
        isRTL: false
    },
    DISCOUNT: {
        content: 'Special discount: `EXAM40`',
        link: websiteLinks.STORE,
        buttonLabel: 'Redeem',
        newTab: false,
        isRTL: false
    },
    TIPS_FOR_EXAM: {
        content: 'טיפים ליצור מבחנים טובים יותר',
        link: 'https://www.youtube.com/watch?v=xvMNsaUZR10&t=206s&ab_channel=irispelled',
        buttonLabel: 'לחצו כאן',
        newTab: true,
        isRTL: true
    },
    YARDEN: {
        content: ' קוד קופון לקניית מבחנים נוספים - YARDEN40',
        link: websiteLinks.STORE,
        buttonLabel: 'לחצו לקבלת ההטבה',
        newTab: false,
        isRTL: true
    },
    LINOY: {
        content: ' קוד קופון לקניית מבחנים נוספים - LINOY20',
        link: websiteLinks.STORE,
        buttonLabel: 'לחצו לקבלת ההטבה',
        newTab: false,
        isRTL: true
    }
};

export const logLevels = {
    ALL: 'ALL',
    INFO: 'INFO',
    ERROR: 'ERROR',
    DEBUG: 'DEBUG',
    WARNING: 'WARNING'
}

export const stages = [
    {
        id: 'input',
        key: 'input',
    },
    {
        id: 'parameters',
        key: 'parameters',
    },
    {
        id: 'personalized',
        key: 'personalized',
    },
    {
        id: 'generate',
        key: 'generate',
    },
];

