import React, { useState } from 'react';
import { Button, Menu, MenuItem, Box } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useLanguage, languageCodes } from '../contexts/languageContext';
import USAFlag from '../assets/flags/united-states-flag-icon.png';
import IsraelFlag from '../assets/flags/israel-flag-icon.png';

const flagImages = {
    'en': USAFlag,
    'he': IsraelFlag,
};

function ChooseLanguageBtn({ handleToggleLanguage = null }) {
    const { language, ToggleLanguage } = useLanguage();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (newLangCode) => {
        ToggleLanguage(newLangCode);
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="text" onClick={handleButtonClick} sx={{ minWidth: '60px' }}>
                <LanguageIcon sx={{ marginRight: 1 }} /> &nbsp;
                {language}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {Object.entries(languageCodes).map(([key, code]) => (
                    <MenuItem key={code} onClick={() => handleLanguageChange(code)}>
                        <img
                            src={flagImages[code]}
                            alt={`${code} flag`}
                            style={{ width: '20px', height: '20px', marginRight: '8px' }}
                        />
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default ChooseLanguageBtn;
