import { HttpStatusCode } from 'axios'
import api from './api'
import { removeJWT } from '../utils/JWT_functions'
import { processResponsePromise } from './utils'

export const isVerified = async () => {
  try {
    const response = await api.get('/auth/verified')
    if (response.status === HttpStatusCode.Ok) {
      return response.data
    } else {
      throw new Error(`Request failed with status ${response.status}`)
    }
  } catch (error) {
    console.error(error)
    return null
  }
}
export const getLoggedInUser = async () => {
  return api.get('/auth/logged').then(response => {
    if (response.status === HttpStatusCode.Ok) {
      return response.data
    } else {
      return Promise.reject(`Request failed with status ${response.status}`)
    }
  }).catch(err => {
    console.error(err)
    return Promise.reject(err)
  })
}

export const logout = async () => {
  return api.post('/auth/logout').then(res => {
    removeJWT()
    return res
  }).catch(err => {
    console.error('logout failed: ', err)
    return null
  })
}

export const forgotPassword = (email) => {
  return api.post('/auth/forgot-password', { email })
}

export const verifyCode = (code, reset_token) => {
  return api.post('/auth/forgot-password/confirm', { code, reset_token })
}

export const resetPassword = (password, email, reset_token) => {
  return api.post('/auth/reset-password', { password, email, reset_token })
}

export const loginMoeIL = async () => {
  return processResponsePromise(api.get('/auth/login/moe/il'))
}