import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardContent, Grid, Button, Box, LinearProgress, CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { saveOnboardingData, getOnboardingData } from '../../requests/onboarding';
import useSnackBar from '../../hooks/useSnackBar';
import RoleSelectionStep from './RoleSelectionStep';
import SubjectSelectionStep from './SubjectSelectionStep';
import SummaryStep from './SummaryStep';
import DetailsInputStep from './DetailsInputStep';
import { homepage } from '../../components/menu/MenuLinks';
import { colors } from '../../utils/customTheme';
import ROLES from '../../utils/roles';
import { websiteLinks } from '../../utils/configs';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import UploadPastTestsStep from './UploadPastTestsStep';
import { useUser } from '../../hooks/useUser';
import { useLoader } from '../../hooks/useLoader';
import { loaderTypes } from '../../utils/loaderTypes';
import { Helmet } from 'react-helmet-async';
import { getOnboardingRoles } from '../../utils/roles';
import Header from '../../components/Header';
import { useLanguage } from '../../contexts/languageContext';

const OnboardingFlow = () => {
  const { languageData, isRTL } = useLanguage();
  const onboardingRoles = useMemo(() => languageData ? getOnboardingRoles(languageData) : [], [languageData]);

  const { user } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const { openLoader, closeLoader } = useLoader();

  const [institutionType, setInstitutionType] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [level, setLevel] = useState('');
  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const [pastTestsFiles, setPastTestsFiles] = useState([]);

  const navigate = useNavigate();
  const { openSnackBar } = useSnackBar();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  useEffect(() => {
    const fetchOnboardingData = async () => {
      try {
        const response = await getOnboardingData();
        updateOnboardingState(response);
      } catch (error) {
        console.error('Error fetching onboarding data:', error);
      }
    };

    fetchOnboardingData();
  }, []);

  useEffect(() => {
    const step = searchParams.get('step');
    if (step && step >= 0 && step < steps.length) {
      setCurrentStep(parseInt(step));
    }
  }, [searchParams.get('step')]);

  const updateOnboardingState = (response) => {
    if (response) {
      if (response.role) {
        const role = onboardingRoles.find(r => r.role === response.role);
        setSelectedRoleId(role ? role._id : null);
      }
      if (response.subjects) {
        setSelectedSubjects(response.subjects);
      }
      if (response.institution) {
        setInstitutionType(response.institution);
      }
      if (response.name) {
        setInstitutionName(response.name);
      }
      if (response.level) {
        setLevel(response.level);
      }
    }
  };

  const selectedRole = useMemo(() => {
    return onboardingRoles.find(role => role._id === selectedRoleId)?.role;
  }, [selectedRoleId]);

  async function handleSaveOnboardingData(selectedRole, selectedSubjects = [], institutionType = '', institutionName = '', level = '', pastTestsFiles = []) {
    setNextBtnLoading(true);

    try {
      openLoader(loaderTypes.ONBOARDING);
      const saveSuccess = await saveOnboardingData({ type: selectedRole, subjects: selectedSubjects, institution: institutionType, name: institutionName, level, pastTestsFiles });
      if (saveSuccess) {
        openSnackBar(SnackbarTypes.ONBOARDING_DATA_SAVED_SUCCESS.field);
        navigate(homepage);
      } else {
        throw new Error('Failed to save onboarding data');
      }
    } catch (error) {
      console.error('Error saving onboarding data:', error);
      openSnackBar(SnackbarTypes.ERROR.field);
    } finally {
      setNextBtnLoading(false);
      closeLoader();
    }
  }

  const handleNext = () => {
    if (selectedRole === ROLES.ENTERPRISE.label && currentStep === 1) {
      setCurrentStep(prevStep => prevStep + 2);
    } else if (currentStep === 2 && !ROLES.canUploadPastTests(user?.roles, selectedRole)) {
      setCurrentStep(prevStep => prevStep + 2);
    } else {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const handleRoleSelection = (roleId) => {
    setSelectedRoleId(roleId);
  };

  const handleSubjectSelection = (subjects) => {
    setSelectedSubjects(subjects);
  };

  const handleFileAdded = (fileText) => {
    setPastTestsFiles(pastTestsFiles => [...pastTestsFiles, fileText]);
  };

  const isDisabled = () => {
    if (currentStep === 0 && !selectedRoleId) return true;
    if (currentStep === 1 && selectedSubjects.length === 0) return true;
    if (currentStep === 2 && (!institutionType || !institutionName || !level)) return true;
    return false;
  };

  const handleStartFreeTrial = async () => {
    handleSaveOnboardingData(selectedRole, selectedSubjects, institutionType, institutionName, level, pastTestsFiles);
  };

  const steps = [
    {
      name: 'RoleSelection',
      component: RoleSelectionStep,
      props: { selectedRoleId, onSelect: handleRoleSelection },
    },
    {
      name: 'SubjectSelection',
      component: SubjectSelectionStep,
      props: { selectedSubjects, handleSubjectSelection },
    },
    {
      name: 'DetailsInput',
      component: DetailsInputStep,
      props: { institutionType, setInstitutionType, institutionName, setInstitutionName, level, setLevel },
    },
    {
      name: "UploadPastTests",
      component: UploadPastTestsStep,
      props: { handleFileAdded },
    },
    {
      name: 'Summary',
      component: SummaryStep,
      props: {
        selectedRole,
        selectedSubjects,
        institutionName,
        level,
        onStartFreeTrial: handleStartFreeTrial,
        nextBtnLoading,
      },
    },
  ];

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <>
      <Header />
      <Helmet>
        <title>Exam Generator | Onboarding</title>
        <meta name="description" content="Begin your journey with the AI-powered exam generator. Our onboarding flow will guide you through creating high-quality, personalized exams in seconds. Sign up today and start boosting learning outcomes with ease!" />
      </Helmet>
      <div dir={isRTL ? "rtl" : "ltr"}>
        <Grid container justifyContent="center" alignItems="center" sx={{ bgcolor: colors.onboarding, minHeight: '100vh', alignItems: 'flex-start' }}>
          <Grid item xs={12} md={8}>
            <LinearProgress
              variant="determinate"
              value={((currentStep + 1) / steps.length) * 100}
              sx={{
                marginTop: 2, height: 5, borderRadius: 5,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: colors.main,
                },
              }}
            />
            <Card sx={{ backgroundColor: colors.onboarding, border: 'none' }} variant="outlined">
              <CardContent>
                <Box mt={2} mb={5}>
                  <CurrentStepComponent {...steps[currentStep].props} />
                </Box>
                {(currentStep < steps.length) &&
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                      position: 'fixed',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: 1,
                    }}>
                    {currentStep !== 0 && (
                      <Button sx={{ m: 2, minWidth: '150px' }} variant='outlined' onClick={handleBack}>
                        {languageData?.onboarding?.back || 'Back'}
                      </Button>
                    )}

                    {currentStep !== steps.length - 1 && (<Button sx={{ m: 2, minWidth: '150px' }} variant='contained' onClick={handleNext} disabled={isDisabled()}>
                      {!nextBtnLoading ?
                        (languageData?.onboarding?.next || 'Next') :
                        <CircularProgress sx={{ color: 'white' }} size={24} />
                      }
                    </Button>)}
                  </Box>}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default OnboardingFlow;

const ProgressWithSteps = ({ currentStep, steps, colors }) => {
  const progressValue = ((currentStep + 1) / steps.length) * 100;

  return (
    <Box sx={{ width: '100%', position: 'relative', paddingBottom: 3 }}>
      {/* Circles representing steps */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', top: 10, width: '100%' }}>
        {steps.map((_, index) => (
          <Box key={index} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                width: 16,
                height: 16,
                borderRadius: '50%',
                backgroundColor: index <= currentStep ? colors.main : '#ccc',
                marginBottom: 1,
              }}
            />
            <Typography variant="caption">{index + 1}</Typography>
          </Box>
        ))}
      </Box>

      {/* Linear Progress Bar */}
      <LinearProgress
        variant="determinate"
        value={progressValue}
        sx={{
          marginTop: 4, // Adjusted to accommodate circles
          height: 5,
          borderRadius: 5,
          '& .MuiLinearProgress-bar': {
            backgroundColor: colors.main,
          },
        }}
      />
    </Box>
  );
};