import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Grid, Button, Typography, Paper, TextField, Divider, Collapse } from '@mui/material';
import StudentDetails from './StudentDetails';
import DisplayMultipleChoice from './DisplayMultipleChoice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { customToFixed, EMPTY_STRING } from '../../utils';
import Graph from '../../components/Recharts/Graph';
import { questionsType } from '../../utils/examOptions';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import ChipBox from '../../components/ChipBox';
import CustomLatex from '../../components/CustomLatex';
import Unseen from '../../components/Unseen';
import { colors } from '../../utils/customTheme';

const getInitNestedAnswersGrade = (nested_answers) => {
  if (!nested_answers) return null;
  return nested_answers.map(ans => ({ question_id: ans.questionId, grade: parseInt(ans.grade) }));
};
const getInitNestedAnswersFeedback = (nested_answers) => {
  if (!nested_answers) return null;
  return nested_answers.map(ans => ({ question_id: ans.questionId, indicator_breakdown: ans.indicator_breakdown || [], feedback: ans.teacher_feedback || "" }));
};

const ExamResponse = ({ isRTL, languageData, student: studentResponse, examJson, onSubmit }) => {
  const { openSnackBar } = useSnackBar()
  /**@type {[{feedback: string, question_id: string, indicator_breakdown: array,nested_feedbacks: [any]}[], Function]} */
  const [feedbacks, setFeedbacks] = useState([]);
  /**@type {[{grade: number, question_id: string,nested_grades:[any]}[], Function]} */
  const [grades, setGrades] = useState([]);
  const [generalFeedback, setGeneralFeedback] = useState("");
  const [gradeErrors, setGradeErrors] = useState([]);
  const [totalGrade, setTotalGrade] = useState(0);
  const maxGrade = useMemo(() => examJson.questions.reduce((acc, curr) => acc + parseInt(curr.grade), 0), [examJson.questions]);

  const [expandedInstructions, setExpandedInstructions] = useState(false);
  const [expandedText, setExpandedText] = useState(false);

  const [expandQuestion, setExpandQuestion] = useState({ [examJson?.questions[0]?._id]: true }); // init open first question
  const handleToggleInstructions = () => setExpandedInstructions(prev => !prev);
  const handleToggleText = () => setExpandedText(prev => !prev);

  const handleToggleQuestion = (id) => {
    setExpandQuestion(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };


  const resetResponse = (studentResponse) => {
    setTotalGrade(parseInt(studentResponse?.grade));
    setFeedbacks(studentResponse?.answers.map(answer => ({ indicator_breakdown: answer.indicator_breakdown || [], feedback: answer.teacher_feedback, question_id: answer.question_id, nested_feedbacks: getInitNestedAnswersFeedback(answer?.nested_answers) })) ?? []);
    setGrades(studentResponse?.answers.map(answer => ({ grade: parseInt(answer.grade), question_id: answer.question_id, nested_grades: getInitNestedAnswersGrade(answer?.nested_answers) })) ?? []);
    setGeneralFeedback(studentResponse?.general_feedback ?? "");
    setGradeErrors(Array(studentResponse.answers?.length).fill(false));
  };

  useEffect(() => {
    if (studentResponse) {
      resetResponse(studentResponse);
    }
  }, [studentResponse]);

  useEffect(() => {
    if (grades && grades.length > 0)
      setTotalGrade(grades.reduce((acc, curr) => acc + parseFloat(curr.grade), 0));
  }, [grades]);

  const handleFeedbackChange = (questionId, value) => {
    const newFeedbacks = feedbacks.map((item) => {
      if (item.question_id === questionId) {
        return {
          ...item,
          feedback: value
        };
      } else if (item?.nested_feedbacks) {
        const nestedFeedbacks = item.nested_feedbacks?.map((nestedItem) => {
          if (nestedItem.question_id === questionId) {
            return {
              ...nestedItem,
              feedback: value
            };
          }
          return nestedItem;
        });
        return {
          ...item,
          nested_feedbacks: nestedFeedbacks
        };
      }
      return item;
    });
    setFeedbacks(newFeedbacks);
  };
  const handleGradeChange = (questionId, value, questionGrade) => {
    let newGrade = null
    let newGradeError = false;
    const index = grades.findIndex((grade) => grade.question_id === questionId);

    if (!value.trim()) {
      value = EMPTY_STRING;
    }
    if (validateGrade(value, questionGrade)) {
      newGrade = { grade: parseFloat(value), question_id: questionId };
      newGradeError = false;
      setGrades(prevGrades => prevGrades.map((grade, i) => i === index && newGrade ? newGrade : grade));
    } else {
      newGradeError = true;
    }
    setGradeErrors(prevGradesErrors => prevGradesErrors.map((error, i) => i === index ? newGradeError : error));
  };

  const handleBreakdownChange = (questionId, newIndicatorBreakdown, questionGrade) => {
    setFeedbacks(prevFeedbacks => prevFeedbacks.map((item) => {
      if (item.question_id === questionId) {
        return {
          ...item,
          indicator_breakdown: newIndicatorBreakdown,
        };
      }
      return item;
    }));
    // calculate the new grade
    const newGrade = newIndicatorBreakdown.reduce((acc, curr) => acc + (parseFloat(curr.awarded_percentage) / 100) * questionGrade, 0);
    handleGradeChange(questionId, newGrade.toString(), questionGrade);
  };

  const handleNestedGrades = (id, parentIndex, event, questionGrade) => {
    let nestedAnswerGrade = parseFloat(event.target.value.trim()) || "";
    const newGrades = [...grades];
    const nestedArray = grades[parentIndex]?.nested_grades
    const nestedIndex = nestedArray.findIndex(nested => nested.question_id === id)
    const newGradeErrors = [...gradeErrors];

    let isParentValid = true
    let isCurrentGradeValid = false

    newGrades[parentIndex].nested_grades[nestedIndex] = { question_id: id, grade: nestedAnswerGrade }

    const parentGrade = nestedArray.reduce((acc, nested, i) => {
      if (isParentValid) //check if at least one error was found for a nested grade if so the parent consider in an error state
        isParentValid = validateGrade(nested.grade, questionGrade)
      isCurrentGradeValid = i == nestedIndex ? validateGrade(nested.grade, questionGrade) : isCurrentGradeValid
      return acc + (parseFloat(nested.grade) || 0)
    }, 0)

    newGrades[parentIndex].grade = parentGrade
    newGradeErrors[parentIndex] = !isParentValid;
    if (!isCurrentGradeValid) {
      newGrades[parentIndex].nested_grades[nestedIndex] = { grade: "", question_id: id }
      newGradeErrors[parentIndex] = true
    }

    setTotalGrade(newGrades.reduce((acc, curr) => acc + (parseFloat(curr.grade) || 0), 0));
    setGrades(newGrades);
    setGradeErrors(newGradeErrors);


  };

  const handleGeneralFeedbackChange = (event) => {
    setGeneralFeedback(event.target.value);
  };

  const validateGrade = (grade, questionGrade) => /^\d*\.?\d+$/.test(grade) && parseFloat(grade) >= 0 && parseFloat(grade) <= questionGrade;

  const loadNestedQuestions = (nested_questions, nested_answers, languageData, parentId, parentQuestionGrade, parentIndex) => {
    return nested_questions.map((question, index) => {
      if (question.type === questionsType.open) {
        const parentIndex = grades.findIndex((grade) => grade.question_id == parentId)
        const nestedGrade = grades[parentIndex]?.nested_grades?.find(grade => grade.question_id === question._id)?.grade;
        const nestedFeedbackObj = feedbacks.flatMap(f => f.nested_feedbacks || []).find(f => f.question_id === question._id);
        return (
          <div key={index}>
            <Typography variant="h6">{`${languageData?.question_label ?? ""} ${parentIndex + 1}.${index + 1}`}</Typography>
            <Typography>
              <CustomLatex>{question.question}</CustomLatex>
            </Typography>
            {nested_answers && <Typography variant="body1" gutterBottom sx={{ backgroundColor: colors.bg, padding: 1, borderRadius: 2, mt: 1 }}>
              <CustomLatex>{nested_answers[index]?.answer}</CustomLatex>
            </Typography>}

            {nestedFeedbackObj?.indicator_breakdown?.length ? (
              <ChipBox
                label={languageData?.ai}
                data={{ indicator: nestedFeedbackObj.indicator_breakdown, questionGrade: question.grade }}
              />
            ) : null}
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} sm={10} md={10} lg={12} pb={2}>
                <TextField
                  label={languageData?.teacher_feedback}
                  variant="outlined"
                  fullWidth
                  multiline
                  value={nestedFeedbackObj?.feedback ?? ""}
                  onChange={(event) => handleFeedbackChange(question._id, event.target.value)}
                  placeholder="Enter your feedback here"
                  margin="normal"
                  rows={3}
                />
              </Grid>
              <Grid item xs={3} sm={1} md={1} lg={1} >
                <TextField
                  label={languageData?.grade}
                  variant="outlined"
                  type="text"
                  value={nestedGrade}
                  onChange={(event) => handleNestedGrades(question._id, parentIndex, event, question.grade, parentQuestionGrade)}
                  error={!validateGrade(nestedGrade, question.grade)}
                  placeholder="Enter answer grade"
                  fullWidth
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2">{`/ ${parseInt(question.grade)}`}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 3 }} />
          </div>
        );
      }
      return null;
    });
  };

  const handleSubmit = (isSaving) => {
    const validGrade = examJson.questions.every(question => {
      const review_index = grades.findIndex(g => g.question_id === question._id);
      return grades[review_index].grade <= question.grade && !gradeErrors[review_index];
    });

    if (!validGrade) {
      openSnackBar(SnackbarTypes.GRADE_ERROR.field);
      return;
    }
    onSubmit({
      feedbacks,
      grades,
      generalFeedback,
      isSaving,
    });
  };

  if (!studentResponse) {
    return <Typography>No student data available.</Typography>;
  }


  return (
    <Grid container spacing={2}>
      <Grid item xs={10} sm={6} md={6} lg={6}>
        <StudentDetails student={{ ...studentResponse, grade: totalGrade, maximumGrade: maxGrade }} />
      </Grid>
      {examJson.instructions && (
        <Grid item xs={12} sm={12} md={12} lg={12} mb={"20px"}>
          <Grid container alignItems="center" >
            <Typography variant="h6">{languageData?.instructions || "View Instructions"}</Typography>
            <IconButton onClick={handleToggleInstructions}>
              {expandedInstructions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
          <Collapse in={expandedInstructions}>
            <Unseen text={examJson.instructions} />
          </Collapse>
        </Grid>
      )}

      {/* Collapsible section for examJson.text */}
      {examJson.text && (
        <Grid item xs={12} mb={"20px"}>
          <Grid container alignItems="center" >
            <Typography variant="h6">{languageData?.text || "View Text"}</Typography>
            <IconButton onClick={handleToggleText}>
              {expandedText ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
          <Collapse in={expandedText}>
            <Unseen text={examJson.text} />
          </Collapse>
        </Grid>
      )}
      {examJson?.questions.map((question, index) => {
        const studentAnswer = studentResponse.answers.find(answer => answer.question_id === question._id);
        const answerFeedbackObj = feedbacks.find(f => f.question_id === question._id);
        const gradeObj = grades.find(f => f.question_id === question._id);
        const isQuestionExpanded = expandQuestion[question._id];

        return (
          <Grid item xs={12} key={question._id}>
            <Paper sx={{ p: 3, mb: 2 }}>
              <Typography variant="h5" onClick={() => handleToggleQuestion(question._id)} sx={{ cursor: 'pointer' }}>
                {`${languageData?.question_label ?? ""} ${index + 1}`}
                <IconButton>
                  {isQuestionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Typography>

              {question?.image?.url && <img src={question.image.url} alt="" style={{ objectFit: 'contain', maxHeight: '300px', width: '100%' }} />}
              <Typography variant="body1" gutterBottom sx={{ fontWeight: "bolder", py: 1 }}>
                <CustomLatex>{question.question}</CustomLatex>
              </Typography>
              <Collapse in={isQuestionExpanded}>
                {question.type === questionsType.graph && question.functions && <Graph fnStrings={question.functions} />}
                {(question.type === questionsType.multiple || question.type === questionsType.fill_in) && (
                  <DisplayMultipleChoice
                    options={question.options}
                    correctAnswers={question.correctAnswers}
                    studentAnswer={studentAnswer?.answer}
                  />
                )}
                {question.type === questionsType.nested &&
                  loadNestedQuestions(question.nested_questions, studentAnswer?.nested_answers, languageData, question._id, question.grade, index)
                }
                {(question.type === questionsType.open || question.type === questionsType.graph) && (
                  <>
                    <Typography variant="body1" gutterBottom sx={{ backgroundColor: colors.bg, padding: 1, borderRadius: 2, mt: 1 }}>
                      <CustomLatex>{studentAnswer?.answer}</CustomLatex>
                    </Typography>
                    <Grid item xs={12} sm={12} md={12} lg={12} pb={2}>
                      <Typography variant="body1" gutterBottom sx={{ fontWeight: "bolder", py: 0, mt: 2 }}>
                        {languageData?.teacher_feedback}
                      </Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        value={answerFeedbackObj?.feedback ?? ""}
                        onChange={(event) => handleFeedbackChange(question._id, event.target.value)}
                        placeholder="Enter your feedback here"
                        margin="normal"
                        minRows={2}
                        maxRows={10}
                      />
                    </Grid>
                  </>
                )}
              </Collapse>

              <Grid container spacing={1}>
                <Collapse in={isQuestionExpanded}>
                  <Grid item xs={12} sm={12} md={12} lg={12} pb={2}>
                    <ChipBox
                      label={languageData?.ai}
                      data={{
                        indicator: (!answerFeedbackObj?.indicator_breakdown || !answerFeedbackObj?.indicator_breakdown?.length) ? question?.explanation : answerFeedbackObj?.indicator_breakdown,
                        questionGrade: question.grade
                      }}
                      onBreakdownChange={(newIndicatorBreakdown) => handleBreakdownChange(question._id, newIndicatorBreakdown, question.grade)}
                    />
                  </Grid>
                  <Grid item sx={{ direction: 'rtl', display: 'flex', alignItems: 'center', justifyContent: isRTL ? 'flex-end' : 'flex-start', mt: 2 }} xs={5} sm={1.5} md={1.5} lg={1.5}>
                    <Typography variant="body2">{`${parseInt(question.grade)}`}</Typography>
                    &nbsp;{`/`}&nbsp;&nbsp;
                    <TextField
                      label={languageData?.grade}
                      variant="outlined"
                      type="text"
                      disabled={!!question.nested_questions}
                      value={customToFixed(gradeObj?.grade)}
                      onChange={(event) => handleGradeChange(question._id, event.target.value, question.grade)}
                      error={gradeErrors[index]}
                      placeholder="Enter answer grade"
                      fullWidth
                    />
                  </Grid>
                </Collapse>

                {!isQuestionExpanded && <Grid item sx={{ display: 'flex', direction: 'ltr', justifyContent: isRTL ? 'flex-end' : 'flex-start' }} xs={10} >
                  <Typography variant="body2">{`${gradeObj?.grade}`}</Typography>
                  &nbsp;
                  <Typography variant="body2">{`/ ${parseInt(question.grade)}`}</Typography>
                </Grid>}
              </Grid>
            </Paper>
          </Grid >
        );
      })}
      <Grid item xs={12}>
        <Paper sx={{ padding: '16px', marginBottom: '16px' }}>
          <Typography variant="h6">{languageData.general_feedback}</Typography>
          <TextField
            minRows={3}
            maxRows={10}
            label={languageData.general_feedback_label}
            variant="outlined"
            fullWidth
            multiline
            value={generalFeedback}
            onChange={handleGeneralFeedbackChange}
            placeholder="Enter general feedback"
            margin="normal"
          />
        </Paper>
      </Grid>
      <Grid item >
        <Paper
          sx={{
            padding: '16px',
            marginBottom: '16px',
            marginLeft: '16px',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          <Button variant="contained" onClick={() => handleSubmit(false)}>
            {languageData.submit}
          </Button>
          <Button variant="text" onClick={() => handleSubmit(true)}>
            {languageData.save}
          </Button>
        </Paper>
      </Grid>
    </Grid >
  );
};

export default ExamResponse;
