import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsDialog from './SettingsDialog';

const SettingsButton = ({ onSubmit, examSettings }) => {
  const [open, setOpen] = useState(false);

  const handleOpenSettings = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleOpenSettings}
      >
        <SettingsIcon />
      </IconButton>
      <SettingsDialog open={open} onClose={handleClose} onSubmit={onSubmit} examSettings={examSettings} />
    </div>
  );
};

export default SettingsButton;
