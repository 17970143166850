import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { moeILAuth } from '../requests/moeAuth';
import { homepage, onboarding } from '../components/menu/MenuLinks';
import { HttpStatusCode } from 'axios';
function NotAuthorized() {
    return (
        <div>
            <p>Not Authorized</p>
        </div>
    );
}

export default function IdmMoeILRedirect() {
    const naviagte = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const [authorized, setAuthorized] = React.useState(true);

    useEffect(() => {
        if (!code || !state) {
            setAuthorized(false);
        } else {
            setAuthorized(true);
            moeILAuth(code, state).then(res => {
                if (res.status === HttpStatusCode.Ok) {
                    // user found
                    naviagte(homepage);
                } else if (res.status === HttpStatusCode.Created) {
                    // user created
                    naviagte(onboarding);
                } else {
                    setAuthorized(false);
                }
            }).catch(err => {
                console.error(err);
                setAuthorized(false);
            });
        }
    }, [code, state])

    if (!authorized) {
        return <NotAuthorized />
    }

    return (
        <div>
            <p>Redirecting...</p>
        </div>
    );
}