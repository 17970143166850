import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { requestAddUpvote } from '../../requests/hub';
import { isMobile } from '../../utils';


const MAX_TITLE_CHARS = isMobile ? 30 : 60;
const ExamHubList = ({ examHubItems, handleUpvote, onBack, onOpen }) => {
    const [clicked, setClicked] = useState({});

    const handleUpvoteClick = async (examId) => {
        try {
            const res = await requestAddUpvote(examId)
            if (res) {
                handleUpvote(examId)
                setClicked({ ...clicked, [examId]: true });
            }
        } catch (error) {
            console.error('Error adding upvote:', error);
        }
    };

    return (
        <div>
            <Button variant="outlined" color="primary" sx={{ mb: 2 }} onClick={() => { onBack(null) }}>
                Go Back
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Upvotes</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Difficulty</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {examHubItems.map((hubItem, index) => {

                            const truncatedTitle = hubItem.title.length > MAX_TITLE_CHARS ? hubItem.title.substring(0, MAX_TITLE_CHARS) + '...' : hubItem.title;

                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleUpvoteClick(hubItem.exam_id)}
                                            sx={{ color: clicked[hubItem.exam_id] ? 'green' : 'inherit', fontSize: 'small' }}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        {hubItem?.upvotes_count ?? 0}
                                    </TableCell>
                                    <TableCell>{truncatedTitle}</TableCell>
                                    <TableCell>{hubItem.difficulty}</TableCell>
                                    <TableCell>{hubItem.language}</TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="primary" onClick={() => onOpen(hubItem.exam_id)}>
                                            Preview
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    );
};

export default ExamHubList;
