import React from 'react'
import { useState, useEffect } from 'react'
import { Typography, Button, TextField, IconButton, Grid } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


function ShareTab({ onClose, languageData, shareUrl }) {
    
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        setCopied(false);
    }, [])

    const shareData = { title: "Share Exam", url: shareUrl };

    // todo in future
    const handleShareExam = () => {
        if (navigator?.canShare(shareData)) {
            return navigator?.share(shareData)
        } else {
            console.error('Could not share!!')
        }
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareUrl);
        setCopied(true);
    };

    const handleDone = () => {
        onClose();
    };

    const sentences = languageData?.subtitle ? languageData.subtitle.split('<b>') : [];

    // Validation for empty sentences
    if (sentences.length === 0) {
        return (
            <Typography variant="subtitle1" color="error">
                {"Refresh and try again."}
            </Typography>
        );
    }

    return (
        <>
            <Typography variant="subtitle1">
                {sentences[0]}<b>{sentences[1]}</b>{sentences[2]}
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                label="Link"
                value={shareUrl}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleCopyLink}>
                            <ContentCopyIcon />
                        </IconButton>
                    ),
                }}
                readOnly
            />
            {copied && <Typography variant="body2" color="success">{languageData.copied}</Typography>}
            <Grid container spacing={2} marginTop="10px">
                <Grid item>
                    <Button onClick={handleDone} color="primary" variant="outlined">
                        {languageData.done}
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={handleCopyLink} disabled={copied} color="primary" variant="contained">
                        {copied ? languageData.copied : languageData.copy}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default ShareTab