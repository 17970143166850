// React
import React from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';

import './App.css'
import 'katex/dist/katex.min.css';
// Components
// import LandingPage from './pages/HtmlLanding/LandingPage'
// import HebrewLandingPage from './pages/HebrewLandingPage/HebrewLandingPage';
import LandingPageManager from './pages/LandingPageManager';
import AIQuiz from './pages/LandingPage/DisplayFeatures/AIQuiz'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import OnboardingFlow from './pages/OnBoarding/OnboardingFlow';
import ExamPage from './pages/ExamPage'
import ApiDocs from './pages/ApiDocs';
import PreviewPage from './pages/PreviewPage/PreviewPage';
import ReviewPage from './pages/Review/ReviewPage';
import BlogPage from './pages/Blog/BlogPage';
import BlogPost from './pages/Blog/BlogPost';
import Dashboard from './pages/Dashboard/Dashboard'
import B2BDashboard from './pages/Dashboard/B2BDashboard';
import HistoryPage from './pages/History'
import Hub from './pages/HUB/Hub';
import OrgSignUp from './pages/OrgSignUp'
import DesktopMenu from './components/menu/DesktopMenu'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Quiz from './pages/Quiz/Quiz';
import ValidationStopPage from './pages/Validation/ValidationStop';
import ValidationWaitingPage from './pages/Validation/ValidationWaitingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccessiblityToolbar from './components/AccessiblityToolbar';
// Wrappers
import { GoogleOAuthProvider } from '@react-oauth/google'
import { LanguageProvider } from './contexts/languageContext';
import { LoaderProvider } from './hooks/useLoader'
import { UserProvider } from './contexts/UserContext'
import { HistoryProvider } from './contexts/HistoryContext'
import { HubProvider } from './hooks/useHub'
import { SkillsProvider } from './contexts/SkillsContext';
import { SubjectsProvider } from './contexts/SubjectsContext';
// Utils
import { isMobile } from './utils'
import { colors, theme } from './utils/customTheme';
import ShareRedirect from './pages/ShareRedirect/ShareRedirect';
import Pricing from './pages/Pricing/Pricing';
import TermsOfService from './pages/TermsOfServices';
import AccessibilityStatement from './pages/AccessibilityStatement';
import { DashboardProvider } from './contexts/Dashboard/DashboardContext';
import { OrgDashboardProvider } from './contexts/Dashboard/OrgContext';
import EmailsDashboard from './pages/Dashboard/EmailsDashboard';
import { EmailsDashboardProvider } from './contexts/Dashboard/EmailsContext';
import DataRetentionPolicy from './pages/DataRetentionPolicy';
import SkillsKnowledgeAssessment from './pages/LandingPage/DisplayFeatures/SkillsKnowledgeAssessment';
import AutoGrading from './pages/LandingPage/DisplayFeatures/AutoGrading';
import FeedbackGrades from './pages/LandingPage/DisplayFeatures/FeedbackGrades';
import InsightsAnalytics from './pages/LandingPage/DisplayFeatures/InsightsAnalytics';
import MistakenQuestions from './pages/LandingPage/DisplayFeatures/MistakenQuestions';
import StudentsUseCase from './pages/LandingPage/DisplayFeatures/StudentsUseCase';
import TeachersUseCase from './pages/LandingPage/DisplayFeatures/TeachersUseCase';
import AcademiaProfessorsUseCase from './pages/LandingPage/DisplayFeatures/AcademiaProfessorsUseCase';
import OrganizationsUseCase from './pages/LandingPage/DisplayFeatures/OrganizationsUseCase';
import ExamsUseCase from './pages/LandingPage/DisplayFeatures/ExamsUseCase';
import QuizzesAssessmentsUseCase from './pages/LandingPage/DisplayFeatures/QuizzesAssessmentsUseCase';
import HomeworkUseCase from './pages/LandingPage/DisplayFeatures/HomeworkUseCase';
import ClassInteractionsUseCase from './pages/LandingPage/DisplayFeatures/ClassInteractionsUseCase';
import FormativeAssessmentUseCase from './pages/LandingPage/DisplayFeatures/FormativeAssessmentUseCase';
import ResultsPage from './pages/Results/ResultsPage';
import Tutorials from './pages/Tutorials';
import ProtectedRoute from './pages/ProtectedRoute ';
import LobbyPage from './pages/Lobby/LobbyPage';
import { LobbyProvider } from './contexts/LobbyContext';
import ManagerDashboard from './pages/ManagerDashboard/ManagerDashboard';
import { ManagerDashboardProvider } from './contexts/ManagerDashboardContext';
import Personalization from './pages/Personalization';
import { SnackbarProvider } from './hooks/useSnackBar';
import IdmMoeILRedirect from './pages/IdmMoeILRedirect';
import SubjectsDashboard from './pages/SubjectsDashboard/SubjectsDashboard';
import SkillsDashboard from './pages/SkillsDashboard/SkillsDashboard';

// test
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const AppProvider = ({ children }) => (
  <HelmetProvider>
    <GoogleOAuthProvider clientId={googleClientId}>
      <UserProvider>
        <LanguageProvider>
          <LoaderProvider>
            <SnackbarProvider>
              <HistoryProvider>
                <DashboardProvider>
                  <OrgDashboardProvider>
                    <EmailsDashboardProvider>
                      <HubProvider>
                        <SkillsProvider>
                          <SubjectsProvider>
                            <LobbyProvider>
                              <ManagerDashboardProvider>
                                {children}
                              </ManagerDashboardProvider>
                            </LobbyProvider>
                          </SubjectsProvider>
                        </SkillsProvider>
                      </HubProvider>
                    </EmailsDashboardProvider>
                  </OrgDashboardProvider>
                </DashboardProvider>
              </HistoryProvider>
            </SnackbarProvider>
          </LoaderProvider>
        </LanguageProvider>
      </UserProvider>
    </GoogleOAuthProvider>
  </HelmetProvider>
)

function App() {
  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div style={{ backgroundColor: colors.onboarding, minHeight: '100vh', ...(isMobile ? {} : { display: 'flex', justifyContent: 'center', overflow: 'hidden' }) }}>
            <DesktopMenu />
            <div name="content" style={{ width: '-webkit-fill-available' }}>
              <AccessiblityToolbar />
              <Helmet>
                {/* Microsoft Clarity Tracking Code */}
                <script type="text/javascript">
                  {` (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                          })(window, document, "clarity", "script", "kgjkj5gb0x");`}
                </script>
                {/* Google Analytics Tracking Code */}
                {/* Google tag (gtag.js) */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-ZBTCDZSJ4T"></script>
                <script>
                  {`window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments)}
                  gtag('js', new Date());

                  gtag('config', 'G-ZBTCDZSJ4T');`}
                </script>
              </Helmet>
              <Routes>
                <Route path="/exam" element={<ProtectedRoute> <ExamPage /> </ProtectedRoute>} />
                <Route exact path='/' Component={LandingPageManager} />
                <Route path='/login' Component={Login} />
                <Route path='/signup' Component={SignUp} />
                <Route path='/api-docs' Component={ApiDocs} />
                <Route path='/start' element={<ProtectedRoute> <OnboardingFlow /> </ProtectedRoute>} />
                <Route path='/lobby' element={<ProtectedRoute> <LobbyPage /> </ProtectedRoute>} />
                <Route path='/personal' element={<ProtectedRoute> <Personalization /> </ProtectedRoute>} />
                <Route path='/tutorials' Component={Tutorials} />
                <Route path='/org/signup' Component={OrgSignUp} />
                <Route path='/forgot-password' Component={ForgotPassword} />
                <Route path='/quiz' Component={Quiz} />
                <Route path='/preview' element={<ProtectedRoute> <PreviewPage /> </ProtectedRoute>} />
                <Route path="/review" element={<ProtectedRoute> <ReviewPage /> </ProtectedRoute>} />
                <Route path='/results' Component={ResultsPage} />
                <Route path='/blog' Component={BlogPage} />
                <Route path='/blog/:id' Component={BlogPost} />
                <Route path="/history" element={<ProtectedRoute> <HistoryPage /> </ProtectedRoute>} />
                <Route path='/explore' element={<ProtectedRoute> <Hub /> </ProtectedRoute>} />
                <Route path='/share-redirect' element={<ProtectedRoute> <ShareRedirect /> </ProtectedRoute>} />
                <Route path='/pricing' element={<ProtectedRoute> <Pricing /> </ProtectedRoute>} />
                <Route path='/privacy-policy' Component={PrivacyPolicy} />
                <Route path='/tos' Component={TermsOfService} />
                <Route path='/data-retention' Component={DataRetentionPolicy} />
                <Route path='/accessibility' Component={AccessibilityStatement} />
                <Route path='/dashboard' Component={Dashboard} />
                <Route path='/dashboard/org' Component={B2BDashboard} />
                <Route path='/dashboard/org/:org_id' element={<ProtectedRoute> <ManagerDashboard /> </ProtectedRoute>} />
                <Route path='/dashboard/emails' Component={EmailsDashboard} />
                <Route path='/dashboard/subjects' element={<ProtectedRoute> <SubjectsDashboard /> </ProtectedRoute>} />
                <Route path='/dashboard/skills' element={<ProtectedRoute> <SkillsDashboard /> </ProtectedRoute>} />
                <Route path='/validation/stop' Component={ValidationStopPage} />
                <Route path='/validation/waiting' Component={ValidationWaitingPage} />
                <Route path='/idm/moe/il' Component={IdmMoeILRedirect} />


                <Route path='/product'>
                  <Route path='ai-question-generator' element={<AIQuiz />} />
                  <Route path='skills-knowledge-assessment' element={<SkillsKnowledgeAssessment />} />
                  <Route path='auto-grading' element={<AutoGrading />} />
                  <Route path='feedback-grades' element={<FeedbackGrades />} />
                  <Route path='insights-analytics' element={<InsightsAnalytics />} />
                  <Route path='mistaken-questions' element={<MistakenQuestions />} />
                  <Route path='*' element={<Outlet />} /> {/* Outlet to render child routes of /product */}
                </Route>

                <Route path='/clients'>
                  <Route path='students' element={<StudentsUseCase />} />
                  <Route path='teachers' element={<TeachersUseCase />} />
                  <Route path='professors' element={<AcademiaProfessorsUseCase />} />
                  <Route path='organizations' element={<OrganizationsUseCase />} />
                  <Route path='*' element={<Outlet />} />
                </Route>

                <Route path='/usecase'>
                  <Route path='exams' element={<ExamsUseCase />} />
                  <Route path='quizzes' element={<QuizzesAssessmentsUseCase />} />
                  <Route path='homework' element={<HomeworkUseCase />} />
                  <Route path='class-interactions' element={<ClassInteractionsUseCase />} />
                  <Route path='formative-assessment' element={<FormativeAssessmentUseCase />} />
                  <Route path='*' element={<Outlet />} />
                </Route>


              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </AppProvider>
  )
}

export default App