import { HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, Divider } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import ContinueWithGoogleButton from '../components/GoogleAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { validateEmail, validatePassword } from '../utils/inputValidations';
//hooks:
import useSnackBar from '../hooks/useSnackBar';
import { useUser } from '../hooks/useUser';
import { getOrg, postOrgSignup } from '../requests/organizations';
import { imgSrcPrefix } from '../components/B2B/UploadImage';
import { getTempToken } from '../requests/token';
import { homepage, onboarding } from '../components/menu/MenuLinks';
import { SnackbarTypes } from '../utils/snackbarTypes';
import Header from '../components/Header';
import { useLanguage } from '../contexts/languageContext';

export default function OrgSignUp() {
    // Hooks:
    const navigate = useNavigate();
    const userContext = useUser()
    const location = useLocation();
    const { openSnackBar } = useSnackBar();
    const { languageData, isRTL, fetchLanguageWithToken } = useLanguage();
    const queryParams = new URLSearchParams(location.search);
    const orgId = queryParams.get('id')
    const [org, setOrg] = useState(null);

    async function init() {
        if (!orgId)
            navigate('/signup')
        else {
            const res = await userContext.isLoggedIn()
            if (res)
                navigate(homepage)
            else {
                const token = await getTempToken(orgId)
                if (token) {
                    getOrg(orgId, token).then(org => {
                        if (org) {
                            setOrg(org)
                            return
                        }
                    })
                    fetchLanguageWithToken(token)
                }
                else
                    navigate('/signup')
            }
        }
    }

    useEffect(() => {
        init()
    }, [])

    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [isFormValid, setisFormValid] = useState({ firstName: false, lastName: false, email: false, password: false });

    // ################# Form Functions #################
    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        let newIsFormValid = { ...isFormValid };

        switch (name) {
            case 'firstName':
                newIsFormValid[name] = value.length > 0;
                break;
            case 'lastName':
                newIsFormValid[name] = value.length > 0;
                break;
            case 'email':
                newIsFormValid[name] = handleValidateEmail(value);
                break;
            case 'password':
                newIsFormValid[name] = value.length >= 6;
                break;
            default:
                break;
        }

        setisFormValid(newIsFormValid);
    };
    const handleValidateEmail = (email) => {
        return validateEmail(email) && ((org && org?.email_settings?.email_regex) ? validateEmailWithRegex(email, org?.email_settings?.email_regex) : true)
    }
    const checkValidForm = (obj) => {
        const { firstName, lastName, email, password } = obj
        return firstName && lastName && email && password
    }

    const handleSubmitInvalid = async (event) => {
        event.preventDefault();
        openSnackBar(SnackbarTypes.FORM_INVALID.field)
    }

    const validateEmailWithRegex = (email, regex) => {
        const emailRegex = new RegExp(regex);
        return emailRegex.test(email);
    };

    const handleEmailChange = (event) => {
        const input = event.target.value;
        if (input === '') {
            setEmailError(false);
            return;
        }
        setEmailError(!handleValidateEmail(input));
    };

    const handlePasswordChange = (event) => {
        const input = event.target.value;
        setPasswordError(!validatePassword(input));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!checkValidForm(isFormValid)) {
            openSnackBar(SnackbarTypes.FORM_INVALID.field)
            return
        }
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const payload = {
            user: {
                email: data.get('email'),
                password: data.get('password'),
                firstname: data.get('firstName'),
                lastname: data.get('lastName'),
                orgId: orgId
            }
        }

        postOrgSignup(payload, openSnackBar).then(user => {
            userContext.login(user)
            navigate(onboarding);
        }).catch(err => {
            console.error(err)
            if (err.response.status === HttpStatusCode.Unauthorized) {
                openSnackBar(SnackbarTypes.UNAUTHORIZED_SIGNUP.field)
            } else if (err.response.status === HttpStatusCode.Conflict) {
                openSnackBar(SnackbarTypes.CONFLICT.field)
                navigate('/login')
            } else {
                openSnackBar(SnackbarTypes.ERROR.field)
            }
        }).finally(() => {
            setIsLoading(false);
        })
    };

    return (
        <>
            <Header />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '15px',
                        direction: isRTL ? 'rtl' : 'ltr'
                    }}
                >
                    {org?.logo
                        ? <Avatar alt='logo' src={imgSrcPrefix + org.logo} sx={{ width: '200px', height: '150px', mt: 1 }} />
                        : <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><LockOutlinedIcon /></Avatar>
                    }
                    {/* <Typography component="h1" variant="h5">
                    Sign up
                </Typography> */}
                    {!org?.logo && org?.name &&
                        <Typography variant="body1">{org.name}</Typography>
                    }
                    <Box component="form" noValidate onSubmit={checkValidForm(isFormValid) ? handleSubmit : handleSubmitInvalid} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={languageData?.sign_up?.first_name_label ?? "First Name"}
                                    onChange={handleTextFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={languageData?.sign_up?.last_name_label ?? "Last Name"}
                                    name="lastName"
                                    autoComplete="family-name"
                                    onChange={handleTextFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    type='email'
                                    label={languageData?.sign_up?.email_label ?? "Email Address"}
                                    name="email"
                                    onChange={handleEmailChange}
                                    error={emailError}
                                    helperText={emailError ?
                                        languageData?.sign_up?.invalid_email_helper_text ?? 'invalid email' :
                                        languageData?.sign_up?.org_email_helper_text ?? 'Please use your organization email'}
                                    autoComplete="email"
                                    onInput={handleTextFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    onInput={handleTextFieldChange}
                                    name="password"
                                    label={languageData?.sign_up?.password_label ?? "Password"}
                                    onChange={handlePasswordChange}
                                    type="password"
                                    error={passwordError}
                                    helperText={
                                        passwordError ? languageData?.sign_up?.password_helper_Text ?? 'Password must be at least 6 characters long' : ''
                                    }
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {isLoading ? <CircularProgress color='inherit' size={24} /> : "Sign Up"}
                                </Button>
                            </Grid>
                            {/* <Grid item xs={12}>
                            <Divider>or</Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <ContinueWithGoogleButton />
                            </Stack>
                        </Grid> */}
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} pl={2}>
                                    <Link href='/login' variant="body2">
                                        {languageData?.sign_up?.already_account ?? 'Already have an account? Log in'}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </>
    );
}