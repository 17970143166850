import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Button, Typography, Grid, Paper } from '@mui/material';
import { isMobile, shuffleArray } from '../../utils';
import QuestionTypeManager from './QuestionTypeManager';
import { useLanguage } from '../../contexts/languageContext';
import SubmissionDialog from './SubmissionDialog';
import { questionsType } from '../../utils/examOptions';
import Unseen from '../../components/Unseen';
import MathInput from 'react-math-keyboard';
import { latexDelimiter } from '../../components/CustomLatex';
import { inputTypes } from '../../utils/configs';

const Exam = ({ examJson, onFinish }) => {
    const [answers, setAnswers] = useState(getInitialAnswers);
    const [displaySubmitError, setDisplaySubmitError] = useState(false);
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const { languageData, isRTL } = useLanguage();
    const firstUnansweredQuestionRef = useRef(null);
    const mathFields = useRef({});

    useEffect(() => {
        const saveAnswers = () => {
            const expirationTime = Date.now() + 60 * 60 * 1000; // 1 hour expiration
            localStorage.setItem('examAnswers', JSON.stringify({ answers, expirationTime }));
        };
        if (answers && Object.keys(answers).length > 0) saveAnswers();
    }, [answers]);

    useEffect(() => {
        if (examJson?.settings?.is_supervised) {
            const handleBeforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = '';
            };

            const handleVisibilityChange = () => {
                if (document.hidden) handleFinish(true);
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
    }, [examJson, languageData]);

    const handleAnswer = (questionId, answer) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: answer
        }));
    };

    const handleMathAnswer = (questionId) => {
        if (!mathFields.current[questionId].latex()) return;
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: (prevAnswers[questionId] ?? '') + `${latexDelimiter}${mathFields.current[questionId].latex()}${latexDelimiter}`
        }));
        mathFields.current[questionId].latex('');
    };

    const handleFinish = (didLeaveTab = false) => {
        if (didLeaveTab) {
            let restoredAnswers = { ...answers };
            if (!restoredAnswers || Object.keys(restoredAnswers).length === 0) restoredAnswers = getInitialAnswers();
            onFinish(restoredAnswers, examJson.questions).then(() => {
                localStorage.removeItem('examAnswers'); // Clear data after submission
            }).catch(e => console.error(e));
            return;
        }

        const isValid = validateAnswers();
        if (!isValid) {
            setDisplaySubmitError(true);
            setTimeout(() => { setDisplaySubmitError(false); }, 8000);

            const firstUnansweredQuestion = examJson.questions.find(question => !answers[question._id]);
            if (firstUnansweredQuestion) {
                firstUnansweredQuestionRef.current = firstUnansweredQuestion._id;
                const firstUnansweredQuestionElement = document.getElementById(firstUnansweredQuestionRef.current);
                if (firstUnansweredQuestionElement) {
                    firstUnansweredQuestionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            } else {
                firstUnansweredQuestionRef.current = null;
            }
            return;
        }
        onFinish(answers, examJson.questions).then(() => {
            localStorage.removeItem('examAnswers'); // Clear data after submission
        }).catch(e => console.error(e));
    };

    const validateAnswers = () => {
        const submittedQuestions = Object.keys(answers).filter(answerId => {
            const question = examJson.questions.find((question) => question._id === answerId);
            if (question) {
                return question.type === questionsType.nested
                    ? answers[answerId].length === question.nested_questions.length && answers[answerId].every((ans) => ans.answer)
                    : !!question;
            }
            return false;
        });
        return submittedQuestions.length === examJson.questions.length;
    };

    const closeSubmitDialog = () => setShowSubmitDialog(false);
    const handleSubmitDialog = () => {
        setShowSubmitDialog(false);
        handleFinish();
    };

    const shuffledQuestions = useMemo(() =>
        examJson?.settings?.shuffle_questions ? shuffleArray(examJson.questions) : examJson.questions,
        [examJson.questions]
    );

    if (!examJson) {
        return <Typography variant="body1">Error fetching exam. Please refresh the page or try again later.</Typography>;
    }

    // CSS styles as a constant at the top of the component
    const styles = {
        mathInputContainer: {
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '10px',
            margin: '20px auto',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
            color: '#333',
            textAlign: 'left',
        },
        mathInput: {
            backgroundColor: '#f9f9f9',
            border: '1px solid #ddd',
            padding: '3px',
            borderRadius: '5px',
            fontSize: '16px',
            width: '100%',
            boxSizing: 'border-box',
        },
    };

    return (
        <div dir={isRTL ? 'rtl' : 'ltr'}>
            <Box p={isMobile ? 2 : 5}>
                {examJson.instructions && (
                    <Grid mb={"20px"}>
                        <Unseen text={examJson.instructions} />
                    </Grid>
                )}
                {examJson.text && (
                    <Grid mb={"20px"}>
                        <Unseen text={examJson.text} />
                    </Grid>
                )}
                {shuffledQuestions.map((question, index) => (
                    <Paper sx={{ p: 3, mt: 3 }} key={index}>
                        <Grid key={question._id} container spacing={1} justifyContent="center">
                            <Grid item xs={12} id={question._id}>
                                <Typography variant="h5" gutterBottom>
                                    {`${languageData?.quiz?.question_label} ${index + 1}`}
                                </Typography>
                            </Grid>
                            {question.image?.url && (
                                <Grid item xs={12}>
                                    <img src={question.image.url} alt="" style={{ objectFit: 'contain', maxHeight: '300px', width: '100%' }} />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <QuestionTypeManager
                                    type={question.type}
                                    allQuestionsProps={{
                                        questionId: question._id,
                                        title: question.question,
                                        onAnswer: handleAnswer,
                                        savedAnswer: answers[question._id] || "",
                                    }}
                                    openQuestionProps={{}}
                                    nestedQuestionsProps={{ nestedQuestions: question.nested_questions, parentIndex: index }}
                                    closeQuestionProps={{
                                        options: question?.options,
                                        correctAnswer: question?.correctAnswer,
                                    }}
                                    graphQuestionProps={{
                                        functions: question.functions,
                                    }}
                                />
                                {((question.type === questionsType.open ||
                                    question.type === questionsType.graph
                                ) && examJson?.parameters?.source === inputTypes.math)
                                    && (
                                        <div dir="ltr" id="my-math-input" style={styles.mathInputContainer}>
                                            {/* todo: handle hebrew */}
                                            <Typography variant='body1'>Math Keyboard</Typography>
                                            <MathInput
                                                setMathfieldRef={(mathfield) => mathFields.current[question._id] = mathfield}
                                                style={styles.mathInput}
                                            />
                                            <Button variant='outlined' onClick={e => handleMathAnswer(question._id)}>Submit</Button>
                                        </div>
                                    )}
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
                <Box mt={2}>
                    <Button onClick={() => setShowSubmitDialog(true)} variant="contained" color="primary" fullWidth>
                        {languageData?.quiz?.exam.finish_label}
                    </Button>
                </Box>
                {displaySubmitError && <Typography variant='body1' color='red'>{languageData?.quiz?.completion_msgs.error_msg}</Typography>}
            </Box>
            <SubmissionDialog
                open={showSubmitDialog}
                closeDialog={closeSubmitDialog}
                submitExam={handleSubmitDialog}
            />
        </div>
    );
};

export default Exam;

function getInitialAnswers() {
    const storedAnswers = localStorage.getItem('examAnswers');
    if (storedAnswers) {
        const parsedAnswers = JSON.parse(storedAnswers);
        const expirationTime = parsedAnswers.expirationTime;
        if (expirationTime && Date.now() > expirationTime) {
            localStorage.removeItem('examAnswers');
            return {};
        }
        return parsedAnswers.answers;
    }
    return {};
}
