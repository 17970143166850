import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Card, CardContent, Button, TextField, Grid, LinearProgress } from '@mui/material';
import { getUserGoodQuestions, updateGoodQuestion, deleteGoodQuestion } from '../requests/goodQuestions';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import { paddingPageStyle } from '../utils/customTheme';
import ApproveModal from '../components/ApproveModal';
import useSnackBar from '../hooks/useSnackBar';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { useLanguage } from '../contexts/languageContext';

const QUESTION_LIMIT = 20;

const Personalization = () => {
    const { openSnackBar} = useSnackBar();
    const { languageData, isRTL } = useLanguage();
    const [questions, setQuestions] = useState([]);
    const [editingQuestionId, setEditingQuestionId] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const fetchedQuestions = await getUserGoodQuestions(QUESTION_LIMIT);
                setQuestions(fetchedQuestions);
            } catch (error) {
                openSnackBar(SnackbarTypes.FETCH_FAILED.field);
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, []);

    const handleEdit = async (questionId, updatedQuestion) => {
        try {
            await updateGoodQuestion(questionId, updatedQuestion);
            setQuestions(questions.map(q => q.doc_id === questionId ? { ...q, text: updatedQuestion } : q));
            setEditingQuestionId(null);
            openSnackBar(SnackbarTypes.SAVED_FOR_LATER_SUCCESS.field);
        } catch (error) {
            console.error('Error updating question:', error);
            openSnackBar(SnackbarTypes.SAVED_FOR_LATER_SUCCESS.field);
        }
    };

    const handleCancelEdit = () => {
        setEditingQuestionId(null);
        setEditedText('');
    };

    const handleDelete = async (questionId) => {
        try {
            await deleteGoodQuestion(questionId);
            setQuestions(questions.filter(q => q.doc_id !== questionId));
            setOpenModal(false);
            openSnackBar(SnackbarTypes.EXAM_DELETED.field);
        } catch (error) {
            console.error('Error deleting question:', error);
            openSnackBar(SnackbarTypes.ERROR.field);
        }
    };

    const handleNavigateToOnboarding = async () => {
        navigate('/start?step=3');
    };


    const openDeleteModal = (questionId) => {
        setQuestionToDelete(questionId);
        setOpenModal(true);
    };

    const progress = useMemo(() => (questions.length / QUESTION_LIMIT) * 100, [questions.length]);

    return (
        <>
            <Helmet>
                <title>Exam Generator | Personalization</title>
                <meta name="description" content="Review your past exams and track your progress with our AI-powered exam generator. Easily revisit and analyze previous exams to enhance future learning outcomes." />
            </Helmet>
            <Header />
            <div dir= {isRTL ? "rtl" : "ltr" }>
            <div style={{ ...paddingPageStyle, padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    {languageData?.personalization?.title || 'Personalization'}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {languageData?.personalization?.subtitle || 'Questions the model will utilize during exam creation to deliver more personalized results'}
                </Typography>
                {/* Render a button if no questions exist */}
                {questions.length === 0 && (
                    <Button variant="contained" color="primary" onClick={handleNavigateToOnboarding}>
                        {languageData?.personalization?.upload_past_questions || 'Upload questions from past exams'}
                    </Button>
                )}
                <Grid container spacing={3}>
                    {questions.slice(0, QUESTION_LIMIT).map((question) => (
                        <Grid item xs={12} sm={6} md={4} key={question.doc_id}>
                            <Card style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <CardContent style={{ flexGrow: 1 }}>
                                    {editingQuestionId === question.doc_id ? (
                                        <div>
                                            <TextField
                                                fullWidth
                                                defaultValue={question.text}
                                                onChange={(e) => setEditedText(e.target.value)}
                                                multiline
                                                minRows={2}
                                                maxRows={6}
                                            />
                                            <div style={{ marginTop: '10px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleEdit(question.doc_id, editedText)}
                                                    sx={{ mr: 1 }}
                                                    disabled={editedText.length < 1}
                                                >
                                                    {languageData?.personalization?.save || 'Save'}
                                                </Button>
                                                <Button
                                                    variant="text"
                                                    color="error"
                                                    onClick={handleCancelEdit}
                                                >
                                                    {languageData?.personalization?.cancel || 'Cancel'}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ padding: 6 }}>
                                            <Typography variant="body1">{question.text}</Typography>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => setEditingQuestionId(question.doc_id)}
                                                sx={{ mr: 1, mt: 1 }}
                                            >
                                                {languageData?.personalization?.edit || 'Edit'}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => openDeleteModal(question.doc_id)}
                                                sx={{ mr: 1, mt: 1 }}
                                            >
                                                {languageData?.personalization?.delete || 'Delete'}
                                            </Button>
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <div style={{ width: '100%', position: 'relative', marginTop: '60px' }}>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{ height: 10, borderRadius: 5 }} // thickness and corner radius
                    />
                    <Typography
                        variant="body1"
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            padding: '15px',
                            fontWeight: 'bold'
                        }}
                    >
                        {questions.length} / {QUESTION_LIMIT}
                    </Typography>
                </div>

                <ApproveModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    onConfirm={() => handleDelete(questionToDelete)}
                    title="Confirm Deletion"
                    subtitle="Are you sure you want to delete this question? This action is irreversible."
                    buttonLabel="Delete"
                />
            </div>
        </div>
    </>
    );
};

export default Personalization;