import { reportClick, buttonsIds } from './requests/analytics'
import { LockClock, Assignment, Description, CloudUpload, Link, YouTube, Calculate, VisibilityOff } from '@mui/icons-material';
import { alpha } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { inputTypes, websiteLinks } from './utils/configs';
import { Fragment } from 'react';
import CustomLatex from './components/CustomLatex';

export const responseErrors = {
  errorText: 'something went wrong, please refresh and try again',
}

export function capitalizeFirstLetter(str) {
  return str.substring(0, 1).toUpperCase() + str.substring(1)
}
export function handleSelectedtierIfExist() {
  const selectedTier = localStorage.getItem('selectedTier');
  if (selectedTier) {
    const tier = JSON.parse(selectedTier);
    localStorage.removeItem('selectedTier');
    nevigateToSelectedTier(tier);
  }
}

export function handleRedirectUrlAfterSignIn(navigate, urlIfNoRedirect) {
  const redirectUrl = sessionStorage.getItem('redirectUrl');
  if (redirectUrl) {
    sessionStorage.removeItem('redirectUrl');
    navigate(redirectUrl)
  } else {
    navigate(urlIfNoRedirect)
  }
}
export const isMobile = window.innerWidth <= 760;

export const goToStore = async (navigate) => {
  navigate('/pricing')
}

export const bookDemo = async () => {
  window.open(websiteLinks.SCHOOL(), '_blank')
}

export const orders = {
  ascending: 'asc',
  descending: 'desc'
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === orders.descending
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function nevigateToSelectedTier(link) {
  window.open(link, '_blank')
}

export function contactUs() {
  reportClick(buttonsIds.contactUs)
  let mailtoURL = isMobile
    ? 'mailto: ' + encodeURIComponent('info@studywise.io')
    : 'https://mail.google.com/mail/?view=cm&fs=1&to=info@studywise.io'

  window.open(mailtoURL, '_blank');
}
// Use this for strings that you need to send to the server
export const toSnakeCase = (inputString) => inputString.toLowerCase().replace(/\s+/g, '_');
export const fromSnakeCase = (inputString) => inputString.replace(/_/g, ' ');
export const capitalizeWords = (inputString) => {
  return inputString.replace(/_/g, ' ')
    .replace(/\b\w/g, match => match.toUpperCase());
};

export const getIcon = (field) => {
  switch (field) {
    case inputTypes.text:
      return <Description sx={{ color: alpha('#4285f4', 0.6) }} />;
    case inputTypes.topic:
      return <TipsAndUpdatesIcon sx={{ color: alpha('#0f9d58', 0.6) }} />;
    case inputTypes.uploads:
      return <CloudUpload sx={{ color: alpha('#f4b400', 0.6) }} />;
    case inputTypes.url:
      return <Link sx={{ color: alpha('#800080', 0.6) }} />;
    case inputTypes.youtube:
      return <YouTube sx={{ color: alpha('#ff0000', 0.6) }} />;
    case inputTypes.based:
      return <Assignment sx={{ color: alpha('#1a1a1a', 0.6) }} />;
    case inputTypes.uploadYourExam:
      return <Assignment sx={{ color: alpha('#000', 0.6) }} />;
    case inputTypes.math:
      return <Calculate sx={{ color: alpha('#FF0000', 0.6) }} />;
    case inputTypes.unseen:
      return <VisibilityOff sx={{ color: alpha('#8a2be2', 0.6) }} />;
    default:
      return <LockClock sx={{ color: alpha('#aaa', 0.6) }} />;
  }
};

export const getIconColor = (field) => {
  switch (field) {
    case inputTypes.text:
      return alpha('#4285f4', 0.1);
    case inputTypes.topic:
      return alpha('#0f9d58', 0.1);
    case inputTypes.uploads:
      return alpha('#f4b400', 0.1);
    case inputTypes.url:
      return alpha('#800080', 0.1);
    case inputTypes.youtube:
      return alpha('#ff0000', 0.1);
    case inputTypes.based:
      return alpha('#1a1a1a', 0.1);
    case inputTypes.uploadYourExam:
      return alpha('#000', 0.1);
    case inputTypes.math:
      return alpha('#FF0000', 0.1);
    case inputTypes.unseen:
      return alpha('#8a2be2', 0.1);
    default:
      return alpha('#aaa', 0.1);
  }
};

export function generateId(length = 24) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters[randomIndex];
  }

  return id;
}


export const getFontSize = (isTitleLarge = false, isSubtitleSmall = false) => {
  const baseFontSize = 16;
  const screenWidth = window.innerWidth;
  const baseScreenWidth = 1280;
  const scaleFactor = screenWidth / baseScreenWidth;

  let titleSize = 1.2;
  let subtitleSize = 1;

  // Adjust font sizes for very large screens
  if (screenWidth > 1920) {
    titleSize *= 1.3;
    subtitleSize *= 1.3;
  }

  if (isTitleLarge) {
    titleSize *= 1.5;
  }

  if (isSubtitleSmall) {
    subtitleSize *= 0.6;
  }

  const titleFontSize = baseFontSize * scaleFactor * titleSize;
  const subtitleFontSize = baseFontSize * scaleFactor * subtitleSize;

  return {
    title: `${titleFontSize / 16}rem`, // Convert pixels to rem
    subtitle: `${subtitleFontSize / 16}rem` // Convert pixels to rem
  };
};

export const EMPTY_STRING = ''

/**
 * Shuffles an array in place using the Fisher-Yates algorithm.
 * Best for large arrays
 * @param {Array} arr - The array to be shuffled.
 * @returns {Array} - The shuffled array.
 */
export const shuffleArrayInplace = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }

  return arr
}
/**
 * Shuffles the elements of an array in a random order.
 * Returns a new array and does not modify the original array.
 * @param {Array} array - The array to be shuffled.
 * @returns {Array} - The shuffled array.
 */
export const shuffleArray = (array) => {
  return [...array].sort(() => Math.random() - 0.5);
};

export function roundToTwoDecimalPlaces(number) {
  return Math.round(number * 100) / 100;
}

export function renderTextWithBreakLines(text, withLatex = true) {
  if (text === null || text === undefined) return
  if (typeof text !== 'string')
    return text

  const splittedText = text.split('\n')
  if (splittedText.length === 1)
    return withLatex ? <CustomLatex>{text}</CustomLatex> : text

  return splittedText?.map((line, index) => (
    <Fragment key={index}>
      {withLatex ? <CustomLatex>{line}</CustomLatex> : line}
      <br />
    </Fragment>
  ))
}


export const getAccuracyColor = (accuracy) => {
  if (accuracy > 70) {
    return "success";
  } else if (accuracy >= 19 && accuracy <= 69) {
    return "warning";
  } else {
    return "error";
  }
};

export const links = {
  LOGIN: "https://www.exam-generator.com/login",
  SIGNUP: "https://www.exam-generator.com/signup",
  STORE: "https://exam-generator.lemonsqueezy.com/",
  FREE: "https://www.exam-generator.com/signup",
  BASIC:
    "https://exam-generator.lemonsqueezy.com/checkout/buy/67a6317d-7eb2-4a57-a840-89bc23aa7e66",
  PRO: "https://exam-generator.lemonsqueezy.com/checkout/buy/7e806f95-ec75-4477-977c-c527ea3ae71b",
  PREMIUM:
    "https://exam-generator.lemonsqueezy.com/checkout/buy/a10980e6-607c-4121-94d7-3a5935733139",
  INSTAGRAM: "https://www.instagram.com/studywise.io/",
  LINKEDIN: "https://www.linkedin.com/company/101561249/",
  PRIVACY: "https://www.exam-generator.com/privacy-policy",
  DATA: "https://www.exam-generator.com/data-retention",
  ACCESSIBILITY: "https://www.exam-generator.com/accessibility",
  TOS: "https://www.exam-generator.com/tos",
  YOUTUBE: "https://www.youtube.com/@ExamGenerator",
};

export const gradeRanges = [
  '0-49', '50-55', '56-60', '61-65', '66-70', '71-75', '76-80', '81-85', '86-90', '91-95', '96-100'
];

export function customToFixed(num) {
  if (num === null || num === undefined || isNaN(num)) return '';
  const number = parseFloat(num);
  const numParts = number.toString().split('.');
  if (numParts.length === 1) {
    return number.toString();
  }
  const decimalPlaces = numParts[1].length;
  return decimalPlaces >= 2 ? number.toFixed(2) : (decimalPlaces === 1 ? number.toFixed(1) : number.toString());
}