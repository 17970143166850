// SettingsDialog.js
import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Switch, TextField, Button, FormControlLabel, FormHelperText, Tooltip, IconButton } from '@mui/material';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { useLanguage } from '../../contexts/languageContext';

const SettingsDialog = ({ open, onClose, onSubmit, examSettings }) => {
    const { isRTL, languageData } = useLanguage()
    const [settings, setSettings] = useState(examSettings);

    useEffect(() => {
        if (examSettings?.accept_responses === undefined || examSettings?.accept_responses === null) {
            setSettings({ ...examSettings, accept_responses: true });
        } else
            setSettings(examSettings);
    }, [examSettings]);

    const handleToggleChange = (event) => {
        setSettings({ ...settings, [event.target.name]: event.target.checked });
    };

    // todo: validations
    const handleInputChange = (event) => {
        setSettings({ ...settings, [event.target.name]: event.target.value });
    };

    const handleSubmit = () => {
        onSubmit(settings);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} dir={isRTL ? 'rtl' : 'ltr'}>
            <DialogTitle>{languageData?.settings_dialog?.title ?? 'Settings'}</DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '10px' }}>
                    <FormControlLabel
                        control={<Switch checked={settings?.accept_responses} onChange={handleToggleChange} name="accept_responses" />}
                        label={languageData?.settings_dialog?.accept_responses?.label ?? 'Accept Responses'}
                        sx={{ margin: 0 }}
                    />
                    <Tooltip title={languageData?.settings_dialog?.accept_responses?.tooltip ?? 'Allow responses to be submitted'}>
                        <IconButton size="small">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '10px' }}>
                    <FormControlLabel
                        control={<Switch checked={settings?.show_results} onChange={handleToggleChange} name="show_results" />}
                        label={languageData?.settings_dialog?.show_results.label}
                        sx={{ margin: 0 }}
                    />
                    <Tooltip title={languageData?.settings_dialog?.show_results.tooltip} >
                        <IconButton size="small">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '10px' }}>
                    <FormControlLabel
                        control={<Switch checked={settings?.is_supervised} onChange={handleToggleChange} name="is_supervised" />}
                        label={languageData?.settings_dialog?.supervision.label}
                        sx={{ margin: 0 }}
                    />
                    <Tooltip title={languageData?.settings_dialog?.supervision.tooltip}>
                        <IconButton size="small">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '10px' }}>
                    <FormControlLabel
                        control={<Switch checked={settings?.identification_required} onChange={handleToggleChange} name="identification_required" />}
                        label={languageData?.settings_dialog?.identification_required.label}
                        sx={{ margin: 0 }}
                    />
                    <Tooltip title={languageData?.settings_dialog?.identification_required.tooltip}>
                        <IconButton size="small">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '10px' }}>
                    <FormControlLabel
                        control={<Switch checked={settings?.shuffle_questions} onChange={handleToggleChange} name="shuffle_questions" />}
                        label={languageData?.settings_dialog?.shuffle_questions.label}
                        sx={{ margin: 0 }}
                    />
                    <Tooltip title={languageData?.settings_dialog?.shuffle_questions.tooltip}>
                        <IconButton size="small">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <TextField
                    label={languageData?.settings_dialog?.pass_mark.label ?? 'Pass Mark (%)'}
                    type="number"
                    name="pass_mark"
                    value={settings?.pass_mark}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    helperText={languageData?.settings_dialog?.pass_mark.helper_text}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">{languageData?.preview?.cancel_label}</Button>
                <Button onClick={handleSubmit} variant='contained' color="primary">{languageData?.preview?.save_label}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingsDialog;
